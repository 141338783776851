<div class="width-100 flex center column content-padding">


    <section
            [ngClass]="{'padding-horizontal-xl': !mediaService.isMobile(), 'padding-horizontal': mediaService.isMobile()}"
            class="flex column center width-100">
        <div
                [ngClass]="{'row': !mediaService.isMobile(), 'column': mediaService.isMobile()}"
                class="flex flex-space-between-center overflow-hidden content-width">
            <div
                    [ngClass]="{'center-start': !mediaService.isMobile(), 'center': mediaService.isMobile(),
        'width-50': !mediaService.isMobile(), 'width-100': mediaService.isMobile()}"
                    class="flex column padding-vertical-xl margin-left width-50">
                <p
                        [ngClass]="{'center-text': mediaService.isMobile(), 'width-100': mediaService.isMobile()}"
                        class="color-accent bold-text">Next-Gen-Recruiting für dein Unternehmen</p>
                <h1 class="page-header">
                    Dein direkter Zugang zu <br *ngIf="mediaService.getScreenWidth() < 1410"> <span class="highlight">50.000+</span>
                    geprüften Absolvent:innen
                </h1>
                <h1 class="page-header"></h1>
                <p class="regular-text margin-vertical" [ngClass]="{'center-text': mediaService.isMobile()}">
                    Wir vernetzen dein Unternehmen mit qualifizierten Talenten für deine Einstiegspositionen. Besetze
                    deine Stellen mit den Spitzenkräften von morgen!</p>
                <a (click)="navToContact('CompanyHeader')">
                    <button class="accent-button">Jetzt Democall vereinbaren</button>
                </a>
            </div>

            <div
                    *ngIf="!mediaService.isMobile() "
                    [ngClass]="{'width-50': !mediaService.isMobile(), 'width-100': mediaService.isMobile()}"
                    class=" flex center column">

                <!-- Crazy Hired Talents Carousel -->

                <div
                  *ngIf="carouselReady"
                  style="min-height: 500px; overflow: hidden"
                  class="medium-fade-in width-100 flex column">
                    <div class="profile-card margin crazy-carousel-1">
                        <div>
                            <img class="profile-image" src="assets/images/talent-female-1.jpg" alt="">
                        </div>
                        <div class="profile-info">
                            <h3>Lena wurde eingestellt über</h3>
                            <div class="company-info">
                                <img src="assets/images/talentagent-logo.png" alt="">
                            </div>
                        </div>
                    </div>

                    <div class="profile-card margin crazy-carousel-2">
                        <div>
                            <img class="profile-image" src="assets/images/talent-female-2.jpg" alt="">
                        </div>
                        <div class="profile-info">
                            <h3>Nina wurde eingestellt über</h3>
                            <div class="company-info">
                                <img src="assets/images/talentagent-logo.png" alt="">
                            </div>
                        </div>
                    </div>

                    <div class="profile-card margin crazy-carousel-3">
                        <div>
                            <img class="profile-image" src="assets/images/Leon.png" alt="">
                        </div>
                        <div class="profile-info">
                            <h3>Leon wurde eingestellt über</h3>
                            <div class="company-info">
                                <img src="assets/images/talentagent-logo.png" alt="">
                            </div>
                        </div>
                    </div>

                    <div class="profile-card margin crazy-carousel-4">
                        <div>
                            <img class="profile-image" src="assets/images/Jonas.png" alt="">
                        </div>
                        <div class="profile-info">
                            <h3>Jonas wurde eingestellt über</h3>
                            <div class="company-info">
                                <img src="assets/images/talentagent-logo.png" alt="">
                            </div>
                        </div>
                    </div>

                    <div class="profile-card margin crazy-carousel-5">
                        <div>
                            <img class="profile-image" src="assets/images/Sophie.png" alt="">
                        </div>
                        <div class="profile-info">
                            <h3>Sophie wurde eingestellt über</h3>
                            <div class="company-info">
                                <img src="assets/images/talentagent-logo.png" alt="">
                            </div>
                        </div>
                    </div>

                    <div class="profile-card margin crazy-carousel-6">
                        <div>
                            <img class="profile-image" src="assets/images/Marie.png" alt="">
                        </div>
                        <div class="profile-info">
                            <h3>Marie wurde eingestellt über</h3>
                            <div class="company-info">
                                <img src="assets/images/talentagent-logo.png" alt="">
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <div class="flex row-wrap center padding-vertical-xl width-100" *ngIf="!mediaService.isMobile()">
            <div
                    class="flex column center filter-grayscale padding-horizontal partner-logo">
                <img src="assets/images/partner-logos/Curacon.jpg" alt=""></div>
            <div
                    class="flex smaller-logo column center filter-grayscale padding-horizontal partner-logo delay-0-2s">
                <img src="assets/images/partner-logos/MLP.png" alt=""></div>
            <div
                    class="flex smaller-logo column center filter-grayscale padding-horizontal partner-logo delay-0-4s">
                <img src="assets/images/partner-logos/Consileon.png" alt=""></div>
            <div
                    class="flex column center filter-grayscale padding-horizontal partner-logo delay-0-6s">
                <img src="assets/images/partner-logos/AGICAP.png" alt=""></div>
            <div
                    class="flex column center filter-grayscale padding-horizontal partner-logo delay-0-8s">
                <img src="assets/images/partner-logos/b-k-p.jpg" alt=""></div>
        </div>

        <div class="flex row center width-100" style="min-height: 70px; max-width: 100vw; overflow: hidden;"
             *ngIf="mediaService.isMobile()">

            <div
                    class="flex margin-vertical column center filter-grayscale padding-horizontal partner-logo logo-carousel-1">
                <img src="assets/images/partner-logos/Curacon.jpg" alt=""></div>
            <div
                    class="flex margin-vertical smaller-logo column center filter-grayscale padding-horizontal partner-logo logo-carousel-2">
                <img src="assets/images/partner-logos/MLP.png" alt=""></div>
            <div
                    class="flex margin-vertical smaller-logo column center filter-grayscale padding-horizontal partner-logo logo-carousel-3">
                <img src="assets/images/partner-logos/Consileon.png" alt=""></div>
            <div
                    class="flex margin-vertical column center filter-grayscale padding-horizontal partner-logo logo-carousel-4">
                <img src="assets/images/partner-logos/AGICAP.png" alt=""></div>

        </div>

    </section>

    <section
            [ngClass]="{'padding-horizontal-xl': !mediaService.isMobile(), 'padding-horizontal': mediaService.isMobile()}"
            class="flex center column hidden cap-width">

        <div class="padding-vertical-lg"></div>
        <h2 class="big-section-header width-100 center-text">Einfach, effektiv, erfolgreich</h2>
        <h4 class="sub-header">Recruiting mit Talentagent</h4>
        <p class="regular-text center-text" style="max-width: 800px;">
            Ob Wirtschaftswissenschaften, MINT oder Geisteswissenschaften:
            Mit Talentagent sprichst du direkt mit den Spitzenkräften von morgen
            und sicherst dir qualifizierte Talente für deine offenen Positionen.
        </p>


        <div
                class="flex center width-100 padding-lg"
                [ngClass]="{'row-wrap': !mediaService.isMobile(), 'column': mediaService.isMobile()}">

            <div class="card-flat usp-card padding-lg flex column start overflow-hidden">
                <i class="usp-icon mdi mdi-all-inclusive color-marine"></i>
                <h3 class="usp-header color-marine">Flexible Stellenschaltung </h3>
                <p class="regular-text">Schalte beliebig viele Stellenanzeigen ohne feste Laufzeit. Starte, pausiere
                    oder bearbeite deine Anzeigen, ganz nach deinem individuellen Bedarf.</p>
                <i class="backdrop-icon mdi mdi-all-inclusive"></i>
            </div>

            <div class="card-flat usp-card padding-lg flex column start overflow-hidden">
                <i class="usp-icon mdi mdi-check-decagram color-teal"></i>
                <h3 class="usp-header color-teal">Nur qualifizierte Bewerbungen erhalten</h3>
                <p class="regular-text">
                    Alle Bewerbungen werden vorab geprüft, um sicherzustellen, dass du nur qualifizierte Talente
                    erhältst:
                    vom Nachweis deutscher Sprachkenntnisse auf C1-Niveau über die EU-Arbeitserlaubnis bis hin zum
                    Hochschulabschluss.</p>
                <i class="backdrop-icon mdi mdi-check-decagram"></i>
            </div>

            <div class="card-flat usp-card padding-lg flex column start overflow-hidden">
                <i class="usp-icon mdi mdi-bullseye-arrow color-primary"></i>
                <h3 class="usp-header color-primary">Erhöhte Sichtbarkeit für deine Stellenanzeigen</h3>
                <p class="regular-text">
                    Passende Talente werden zusätzlich per WhatsApp, SMS und E-Mail auf deine Stellenanzeigen
                    aufmerksam.
                </p>
                <i class="backdrop-icon mdi mdi-bullseye-arrow"></i>
            </div>

        </div>

        <div class="flex column center width-100 padding-lg">
            <a (click)="navToContact('CompanyPageBenefits')">
                <button class="accent-button">Jetzt Democall vereinbaren</button>
            </a>
        </div>

    </section>

    <div class="content-width width-100">
        <!--
        <section  [ngClass]="{
         'padding-horizontal-xl': !mediaService.isMobile(),
         'padding-horizontal': mediaService.isMobile()}"
                  class="column flex center">
          <h2 class="big-section-header padding-vertical-lg">
            Dein Weg zu den besten Kandidaten der <br *ngIf="mediaService.isMobile()"><span class="highlight">Generation Z</span>
          </h2>
          <div class="grid responsive-grid padding-lg">
            <div
              [ngClass]="{'grid-item-4': !mediaService.isMobile(), 'grid-item': mediaService.isMobile()}"
              class="width-100 card-flat flex center column padding-lg margin-horizontal">
              <h2 class="color-primary center-text margin-bottom">Unternehmensaccount anlegen</h2>
              <p class="color-regular">
                Sichere dir deinen sofortigen Zugang zu Talentagent mit unbegrenzten Recruiter-Lizenzen.
              </p>
            </div>
            <div
              [ngClass]="{'grid-item-4': !mediaService.isMobile(), 'grid-item': mediaService.isMobile()}"
              class="width-100 card-flat flex center column padding-lg margin-horizontal">
              <h2 class="color-primary center-text margin-bottom">Bewerberpaket buchen und Stellen veröffentlichen</h2>
              <p class="color-regular">
                Wähle ein passendes Bewerberpaket aus und schalte flexibel beliebig viele Stellenanzeigen,
                bis dein Kontingent aufgebraucht ist.
              </p>
            </div>
            <div
              [ngClass]="{'grid-item-4': !mediaService.isMobile(), 'grid-item': mediaService.isMobile()}"
              class="width-100 card-flat flex center column padding-lg margin-horizontal">
              <h2 class="color-primary center-text margin-bottom">Bewerber mit Qualitätsgarantie erhalten</h2>
              <p class="color-regular">
                Du erhältst garantiert nur Kandidaten, die einen Nachweis deutscher Sprachkenntnisse,
                die EU-Arbeitserlaubnis und einen Hochschulabschluss haben.
              </p>
            </div>
          </div>
        </section>
        -->

        <section
                [ngClass]="{'padding-horizontal-xl': !mediaService.isMobile(), 'padding-horizontal': mediaService.isMobile()}"
                class="flex center column">
            <br *ngIf="mediaService.isMobile()">
            <h2 class="big-section-header padding-vertical-lg cap-width center-text">
                Dein Weg zu den besten <br> Talenten der <br *ngIf="mediaService.isMobile()"> <span class="highlight">Generation Z</span>
            </h2>
            <div
                    [ngClass]="{'row': !mediaService.isMobile(), 'column': mediaService.isMobile()}"
                    class="flex flex-space-between-center width-100 padding-vertical-lg">
                <div
                        [ngClass]="{'width-50': !mediaService.isMobile(), 'width-100': mediaService.isMobile()}"
                        class="flex column center  padding-lg">
                    <div class="flex center-start column content-box-width">
                        <div class="flex">
                            <div class="white-gradient-overlay"></div>
                            <p class="step-text color-primary remove-margin">01</p>
                        </div>
                        <h3 class="remove-margin">Unternehmensaccount anlegen</h3>
                        <p class="regular-text">
                            Sichere dir deinen sofortigen Zugang zu Talentagent mit unbegrenzten Lizenzen für dein
                            Recruitment-Team.
                        </p>
                    </div>

                </div>

                <div
                        [ngClass]="{'width-50': !mediaService.isMobile(), 'width-100': mediaService.isMobile()}"
                        class="padding-lg flex center">
                    <img class="step-graphic image-100-dvw" src="assets/images/Grafik-1.png" alt="">
                </div>
            </div>

        </section>


        <section
                [ngClass]="{'padding-horizontal-xl': !mediaService.isMobile(), 'padding-horizontal': mediaService.isMobile()}"
                class="flex center column hidden">

            <div class="flex center width-100">
                <img class="arrow-img arrow reveal-image" src="assets/images/arrow.png" alt="">
            </div>

            <div
                    [ngClass]="{'row': !mediaService.isMobile(), 'column': mediaService.isMobile()}"
                    class="flex flex-space-between-center width-100 padding-vertical-lg">
                <div
                        *ngIf="!mediaService.isMobile()"
                        class="padding-lg width-50 flex center">
                    <img class="step-graphic image-100-dvw" src="assets/images/Grafik-2.png" alt="">
                </div>

                <div
                        [ngClass]="{'width-50': !mediaService.isMobile(), 'width-100': mediaService.isMobile()}"
                        class="flex column center padding-lg">
                    <div class="flex center-start column content-box-width">
                        <div class="flex">
                            <div class="white-gradient-overlay"></div>
                            <p class="step-text color-yellow-highlight remove-margin">02</p>
                        </div>
                        <h3 class="remove-margin">Bewerberpaket buchen und Stellen veröffentlichen</h3>
                        <p class="regular-text">
                            Wähle ein passendes Bewerberpaket aus und schalte flexibel beliebig viele Stellenanzeigen,
                            bis dein Kontingent aufgebraucht ist.
                        </p>
                    </div>
                </div>

                <div
                        *ngIf="mediaService.isMobile()"
                        class="padding-lg width-100 flex center">
                    <img class="step-graphic image-100-dvw" src="assets/images/Grafik-2.png" alt="">
                </div>
            </div>

        </section>

        <section
                [ngClass]="{'padding-horizontal-xl': !mediaService.isMobile(), 'padding-horizontal': mediaService.isMobile()}"
                class="flex center column hidden">

            <div class="flex center width-100">
                <img class="arrow-img-flip arrow reveal-image" src="assets/images/arrow.png" alt="">
            </div>

            <div
                    [ngClass]="{'row': !mediaService.isMobile(), 'column': mediaService.isMobile()}"
                    class="flex flex-space-between-center row width-100 padding-vertical-lg">
                <div
                        [ngClass]="{'width-50': !mediaService.isMobile(), 'width-100': mediaService.isMobile()}"
                        class="flex column center padding-lg">
                    <div class="flex center-start column content-box-width">
                        <div class="flex">
                            <div class="white-gradient-overlay"></div>
                            <p class="step-text color-primary remove-margin">03</p>
                        </div>
                        <h3 class="remove-margin">Bewerbungen mit Qualitätsgarantie erhalten</h3>
                        <p class="regular-text">
                            Du erhältst garantiert nur Talente, die einen Nachweis deutscher Sprachkenntnisse, die
                            EU-Arbeitserlaubnis und einen Hochschulabschluss haben.
                        </p>
                    </div>
                </div>

                <div
                        [ngClass]="{'width-50': !mediaService.isMobile(), 'width-100': mediaService.isMobile()}"
                        class="padding-lg flex center">
                    <img class="step-graphic image-100-dvw" src="assets/images/Grafik-3.png" alt="">
                </div>
            </div>
        </section>

        <section
                [ngClass]="{'padding-horizontal-xl': !mediaService.isMobile(), 'padding-horizontal': mediaService.isMobile()}"
                class="flex center column hidden">

            <div class="flex center width-100">
                <img class="arrow-img arrow reveal-image" src="assets/images/arrow.png" alt="">
            </div>

            <div
                    [ngClass]="{'row': !mediaService.isMobile(), 'column': mediaService.isMobile()}"
                    class="flex flex-space-between-center width-100 padding-vertical-lg">
                <div
                        *ngIf="!mediaService.isMobile()"
                        class="padding-lg width-50 flex center">
                    <img class="step-graphic image-100-dvw" src="assets/images/Grafik-4.png" alt="">
                </div>

                <div
                        [ngClass]="{'width-50': !mediaService.isMobile(), 'width-100': mediaService.isMobile()}"
                        class="flex column center padding-lg">
                    <div class="flex center-start column content-box-width">
                        <div class="flex">
                            <div class="white-gradient-overlay"></div>
                            <p class="step-text color-yellow-highlight remove-margin">04</p>
                        </div>
                        <h3 class="remove-margin">Mit Talenten kommunizieren und Stellen besetzen</h3>
                        <p class="regular-text">
                            Passende Talente kannst du direkt über die Chat-Funktion kontaktieren.
                            Fordere unkompliziert fehlende Unterlagen an, lade zu einem Vorstellungsgespräch ein und
                            stehe für Fragen zur Verfügung.
                        </p>
                    </div>
                </div>

                <div
                        *ngIf="mediaService.isMobile()"
                        class="padding-lg width-100 flex center">
                    <img class="step-graphic image-100-dvw" src="assets/images/Grafik-4.png" alt="">
                </div>
            </div>

        </section>

        <section
                [ngClass]="{'padding-horizontal-xl': !mediaService.isMobile(), 'padding-horizontal': mediaService.isMobile()}"
                class="flex center column hidden">

            <div class="flex center width-100">
                <img class="arrow-img-flip arrow reveal-image" src="assets/images/arrow.png" alt="">
            </div>

            <div
                    [ngClass]="{'row': !mediaService.isMobile(), 'column': mediaService.isMobile()}"
                    class="flex flex-space-between-center row width-100 padding-vertical-lg">
                <div
                        [ngClass]="{'width-50': !mediaService.isMobile(), 'width-100': mediaService.isMobile()}"
                        class="flex column center padding-lg">
                    <div class="flex center-start column content-box-width">
                        <div class="flex">
                            <div class="white-gradient-overlay"></div>
                            <p class="step-text color-primary remove-margin">05</p>
                        </div>
                        <h3 class="remove-margin">Stellenanzeigen analysieren und optimieren</h3>
                        <p class="regular-text">
                            Mit der integrierten Controlling-Funktion
                            kannst du die Performance deiner Stellenanzeigen überwachen und deine Recruiting-Strategie
                            so kontinuierlich verbessern.
                            Unser Team unterstützt dich bei der Planung der Kampagne und der Nutzung der Plattform.
                        </p>
                    </div>
                </div>

                <div
                        [ngClass]="{'width-50': !mediaService.isMobile(), 'width-100': mediaService.isMobile()}"
                        class="padding-lg flex center">
                    <img class="step-graphic image-100-dvw" src="assets/images/Grafik-5.png" alt="">
                </div>
            </div>

            <div class="flex column center width-100 margin-bottom-lg">
                <a (click)="navToContact('CompanyPageSteps')">
                    <button class="accent-button">Jetzt Democall vereinbaren</button>
                </a>
            </div>
        </section>


        <section
                [ngClass]="{'padding-horizontal-xl': !mediaService.isMobile(), 'padding-horizontal': mediaService.isMobile()}"
                class="flex center column hidden">

            <h2 class="big-section-header padding-vertical-lg cap-width center-text">
                Das sagen unsere <br *ngIf="mediaService.isMobile()"> <span class="highlight">Partner</span></h2>

            <div
                    [ngClass]="{'row': !mediaService.isMobile(), 'column': mediaService.isMobile()}"
                    class="flex center-stretch row width-100 padding-vertical-lg">

                <div
                        [ngClass]="{'margin-bottom': mediaService.isMobile()}"
                        class="card-flat padding-lg flex column start margin-horizontal testimonial-card">
                    <i class="mdi mdi-format-quote-close quote-icon-card"></i>

                    <img class="testimonial-logo" src="assets/images/partner-logos/Consileon.png" alt="">

                    <p class="regular-text">
                        “Die AnsprechpartnerInnen sind stets hilfsbereit.
                        Außerdem ist die Handhabung der Plattform benutzerfreundlich
                        und wir haben schon sehr viele gute Bewerber eingestellt.”
                        <br>
                        <strong>Recruiterin bei Consileon</strong>
                    </p>
                </div>

                <div class="card-flat padding-lg flex column start margin-horizontal testimonial-card">
                    <i class="mdi mdi-format-quote-close quote-icon-card"></i>

                    <img class="testimonial-logo" src="assets/images/partner-logos/userlike-logo.png" alt="">

                    <p class="regular-text">
                        “Seit wir mit Talentagent zusammenarbeiten,
                        haben wir stets einen gefüllten Recruiting Funnel für unsere Trainee- & Juniorpositionen.
                        Das erste Hiring, das durch Talentagent zu uns kam, hat bereits beeindruckende Erfolge
                        gefeiert.”
                        <br>
                        <strong>Recruiterin bei Userlike</strong>
                    </p>
                </div>
            </div>
        </section>

        <!-- For the moment not the right spot for this content, maybe later on a blog post -->
        <!--section
          [ngClass]="{'padding-vertical': !mediaService.isMobile(), 'padding-vertical-xl': mediaService.isMobile()}"
          class="flex column center">
          <h2 class="big-section-header padding-vertical-lg">Mehr Erfolg, weniger Aufwand</h2>

          <div class="cap-width">
            <div class="grid width-100 grid-no-gap">
              <div
                [ngClass]="{'grid-item-4': !mediaService.isMobile(), 'grid-item-1': mediaService.isMobile()}"
                class="flex column"></div>
              <div
                [ngClass]="{'grid-item-4': !mediaService.isMobile(), 'grid-item-6': mediaService.isMobile()}"
                class="flex column center padding">
                <div class="round-icon-container background-medium-grey">
                  <i class="mdi mdi-numeric-1-circle-outline"></i>
                </div>
                <p>SENIOR-Hiring</p>
              </div>
              <div
                [ngClass]="{'grid-item-4': !mediaService.isMobile(), 'grid-item-5': mediaService.isMobile()}"
                class="flex column center padding">
                <div class="round-icon-container background-primary">
                  <i class="mdi mdi-numeric-2-circle-outline"></i>
                </div>
                <p>TALENT-Hiring</p>
              </div>
            </div>

            <p *ngIf="mediaService.isMobile()" class="width-100 margin-bottom-sm color-primary bold-text">time-to-hire</p>
            <div class="grid width-100 grid-no-gap">
              <div
                [ngClass]="{'grid-item-4': !mediaService.isMobile(), 'grid-item-1': mediaService.isMobile()}"
                class="flex column first-rider background-primary">
                <i class="mdi mdi-account-group"></i>
                <span *ngIf="!mediaService.isMobile()">time-to-hire</span></div>
              <div
                [ngClass]="{'grid-item-4': !mediaService.isMobile(), 'grid-item-6': mediaService.isMobile()}"
                class="flex column content-rider">6-12 Monate</div>
              <div
                [ngClass]="{'grid-item-4': !mediaService.isMobile(), 'grid-item-5': mediaService.isMobile()}"
                class="flex column content-rider">ca. 2 Monate</div>
            </div>

            <p *ngIf="mediaService.isMobile()" class="width-100 margin-bottom-sm color-marine bold-text">Einstellungskosten</p>
            <div class="grid width-100 grid-no-gap">
              <div
                [ngClass]="{'grid-item-4': !mediaService.isMobile(), 'grid-item-1': mediaService.isMobile()}"
                class="flex column first-rider background-marine">
                <i class="mdi mdi-currency-eur"></i>
                <span *ngIf="!mediaService.isMobile()">Einstellungskosten</span>
                </div>
              <div
                [ngClass]="{'grid-item-4': !mediaService.isMobile(), 'grid-item-6': mediaService.isMobile()}"
                class="flex column content-rider">ca. 20k €</div>
              <div
                [ngClass]="{'grid-item-4': !mediaService.isMobile(), 'grid-item-5': mediaService.isMobile()}"
                class="flex column content-rider">ca. 4k </div>
            </div>

            <p *ngIf="mediaService.isMobile()" class="width-100 margin-bottom-sm color-peach bold-text">time-to-productivity</p>
            <div class="grid width-100 grid-no-gap">
              <div
                [ngClass]="{'grid-item-4': !mediaService.isMobile(), 'grid-item-1': mediaService.isMobile()}"
                class="flex column first-rider background-peach">
                <i class="mdi mdi-clock-outline"></i>
                <span *ngIf="!mediaService.isMobile()">time-to-productivity</span>
                </div>
              <div
                [ngClass]="{'grid-item-4': !mediaService.isMobile(), 'grid-item-6': mediaService.isMobile()}"
                class="flex column content-rider">1-2 Monate</div>
              <div
                [ngClass]="{'grid-item-4': !mediaService.isMobile(), 'grid-item-5': mediaService.isMobile()}"
                class="flex column content-rider">ca. 6 Monate</div>
            </div>


            <p *ngIf="mediaService.isMobile()" class="width-100 margin-bottom-sm color-violet bold-text">total time</p>
            <div class="grid width-100 grid-no-gap">
              <div
                [ngClass]="{'grid-item-4': !mediaService.isMobile(), 'grid-item-1': mediaService.isMobile()}"
                class="flex column first-rider background-violet">
                <i class="mdi mdi-clock-check"></i>
                <span *ngIf="!mediaService.isMobile()">total time</span>
                </div>
              <div
                [ngClass]="{'grid-item-4': !mediaService.isMobile(), 'grid-item-6': mediaService.isMobile()}"
                class=" flex column content-rider">7-14 Monate</div>
              <div
                [ngClass]="{'grid-item-4': !mediaService.isMobile(), 'grid-item-5': mediaService.isMobile()}"
                class=" flex column content-rider">ca. 6-8 Monate</div>
            </div>
          </div>

          <div class="flex column center width-100 padding-lg">
            <button class="accent-button" (click)="navToContact()">Jetzt Democall vereinbaren</button>
          </div>

        </section-->


    </div>

    <section
            [ngClass]="{'padding-xl': !mediaService.isMobile(), 'padding': mediaService.isMobile()}"
            class="flex column center width-100 background-light-teal">
        <div class="cap-width width-100">

            <h2 class="big-section-header padding-vertical-lg start-text">Case Study Userlike</h2>
            <div
                    [ngClass]="{'row': !mediaService.isMobile(), 'column': mediaService.isMobile()}"
                    class="flex column start">
                <div
                        [ngClass]="{'width-50': !mediaService.isMobile(), 'width-100': mediaService.isMobile()}"
                        class="flex column start"
                >
                    <div class="display-video-about">

                        <div style="padding:56.25% 0 0 0;position:relative;">
                            <iframe src="https://player.vimeo.com/video/937795637?h=d40eceacd0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                                    frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
                                    style="position:absolute;top:0;left:0;width:100%;height:100%;"
                                    title="Userlike Testimonial"></iframe>
                        </div>
                        <script src="https://player.vimeo.com/api/player.js"></script>

                        <!--
                        <iframe
                          *ngIf="mediaService.getScreenWidth() > 768"
                          onload="this.width= (screen.width / 3.33);"
                          height="300"
                          src="https://syntacton.sharepoint.com/sites/Marketing/_layouts/15/embed.aspx?UniqueId=2a887986-76b9-44b0-8b4b-b7a14340d32f&embed=%7B%22ust%22%3Atrue%2C%22hv%22%3A%22CopyEmbedCode%22%7D&referrer=StreamWebApp&referrerScenario=EmbedDialog.Create"
                          frameborder="0" scrolling="no" allowfullscreen title="240313_testimonial-kristina_V2.mp4"></iframe>

                        <iframe
                          *ngIf="mediaService.getScreenWidth() <= 768"
                          onload="this.width= (screen.width * 0.95);"
                          height="300"
                          src="https://syntacton.sharepoint.com/sites/Marketing/_layouts/15/embed.aspx?UniqueId=2a887986-76b9-44b0-8b4b-b7a14340d32f&embed=%7B%22ust%22%3Atrue%2C%22hv%22%3A%22CopyEmbedCode%22%7D&referrer=StreamWebApp&referrerScenario=EmbedDialog.Create"
                          frameborder="0" scrolling="no" allowfullscreen title="240313_testimonial-kristina_V2.mp4"></iframe>
                          -->
                    </div>
                    <div class="width-100 flex center padding-vertical-lg">
                        <a routerLink="/caseStudies/userlike" (click)="reportCaseStudyClick()">
                            <button class="accent-button">Mehr erfahren</button>
                        </a>
                    </div>
                </div>

                <div [ngClass]="{'width-50': !mediaService.isMobile(), 'width-100': mediaService.isMobile()}"
                     class="flex column start padding-horizontal">
                    <div
                            [ngClass]="{'column': mediaService.getScreenWidth() <= 768, 'row': mediaService.getScreenWidth() > 768}"
                            class="flex center width-100">
                        <div
                                [ngClass]="{
          'row': mediaService.getScreenWidth() <= 768,
          'start': mediaService.getScreenWidth() <= 768,
          'column': mediaService.getScreenWidth() > 768,
          'center-start': mediaService.getScreenWidth() > 768
          }"
                                class="usp-container first-usp flex padding-lg">
                            <div class="usp-icon-wrapper flex center">
                                <i class="usp-icon mdi mdi-account-heart"></i>
                            </div>
                            <div class="flex column start">
                                <p
                                        [ngClass]="{'quote-author': mediaService.getScreenWidth() <= 768}"
                                        class="bold-text color-regular margin-top">60%</p>
                                <p class="margin-vertical-sm">Interessante Talente</p>
                            </div>
                        </div>

                        <div
                                [ngClass]="{
          'row': mediaService.getScreenWidth() <= 768,
          'start': mediaService.getScreenWidth() <= 768,
          'column': mediaService.getScreenWidth() > 768,
          'center-start': mediaService.getScreenWidth() > 768
          }"
                                class="usp-container second-usp flex padding-lg">
                            <div class="usp-icon-wrapper flex center delay-0-2s">
                                <i class="usp-icon mdi mdi-clock-star-four-points-outline"></i>
                            </div>
                            <div class="flex column start">
                                <p
                                        [ngClass]="{'quote-author': mediaService.getScreenWidth() <= 768}"
                                        class="bold-text color-regular margin-top">2 Monate</p>
                                <p class="margin-vertical-sm">Bis zum ersten Hiring</p>
                            </div>
                        </div>

                        <div
                                [ngClass]="{
          'row': mediaService.getScreenWidth() <= 768,
          'start': mediaService.getScreenWidth() <= 768,
          'column': mediaService.getScreenWidth() > 768,
          'center-start': mediaService.getScreenWidth() > 768
          }"
                                class="usp-container third-usp flex padding-lg">
                            <div class="usp-icon-wrapper flex center delay-0-4s">
                                <i class="usp-icon mdi mdi-text-box-check-outline"></i>
                            </div>
                            <div class="flex column start">
                                <p
                                        [ngClass]="{'quote-author': mediaService.getScreenWidth() <= 768}"
                                        class="bold-text color-regular margin-top">20%</p>
                                <p class="margin-vertical-sm">Konvertierung der Anzeige</p>
                            </div>
                        </div>

                    </div>

                    <div
                            class="flex column start padding-vertical">
                        <br>
                        <p class="quote-text">{{caseStudyData.quoteText}}</p>
                        <br>
                        <!--
                        <p class="quote-author">{{caseStudyData.quoteAuthor}}</p>
                        <p class="quote-author-position">{{caseStudyData.quoteAuthorTitle}}</p>
                        <i class="quote-icon mdi mdi-format-quote-close"></i>
                        -->
                        <br>
                    </div>

                    <div *ngIf="mediaService.isMobile()" class="width-100 flex center padding-vertical-lg">
                        <a routerLink="/caseStudies/userlike">
                            <button class="accent-button">Mehr erfahren</button>
                        </a>
                    </div>
                </div>

            </div>

        </div>

    </section>


    <section [ngClass]="{
     'padding-horizontal-xl': !mediaService.isMobile(),
     'padding-horizontal': mediaService.isMobile()}"
             class="column flex center width-100 cap-width" *ngIf="webinarData?.length">
        <div class="width-100 flex row flex-space-between-center">
            <h2 class="big-section-header padding-vertical-lg start-text">Unsere Webinare</h2>
            <button *ngIf="!mediaService.isMobile()" class="accent-button">Komm zu unserem Webinar</button>
        </div>

        <div class="width-100 cap-width flex gap-lg"
             [ngClass]="{'row': !mediaService.isMobile(), 'column': mediaService.isMobile()}"
             *ngIf="webinarData?.length">

            <div class="guide" [ngClass]="{'width-50': !mediaService.isMobile(), 'width-100': mediaService.isMobile()}"
                 *ngIf="webinarData?.length != 1">
                <app-webinar-card [webinarData]="webinarData[0]" [size]="'Medium'"></app-webinar-card>
            </div>

            <div class="flex column gap-lg"
                 [ngClass]="{'width-50': !mediaService.isMobile(), 'width-100': mediaService.isMobile()}"
                 *ngIf="webinarData?.length != 1">
                <div class="height-50 guide delay-0-5s">
                    <app-webinar-card [webinarData]="webinarData[1]" [size]="'Small'"></app-webinar-card>
                </div>
                <div class="height-50 guide delay-1s" *ngIf="webinarData?.length == 3">
                    <app-webinar-card [webinarData]="webinarData[2]" [size]="'Small'"></app-webinar-card>
                </div>
            </div>

            <div class="guide width-100 flex row center" *ngIf="webinarData?.length == 1">
                <app-webinar-card [webinarData]="webinarData[0]" [size]="'Large'"></app-webinar-card>
            </div>
        </div>

        <div class="padding-vertical-lg width-100 flex row center">
            <button class="accent-button">Komm zu unserem Webinar</button>
        </div>


    </section>


    <section
            [ngClass]="{'padding-vertical': !mediaService.isMobile(), 'padding-vertical-xl': mediaService.isMobile()}"
            class="flex column center width-100">
        <h2 class="big-section-header padding-vertical-lg">Wie viele Bewerbungen brauchst du?</h2>

      <div class="flex row center-start gap-lg width-100">
        <div class="flex column gap-sm pricing-options-text-container" *ngIf="mediaService.getScreenWidth() > 1200">
          <ul class="pricing-options-list flex column gap-sm">
            <li>Garantierte Anzahl an Bewerbungen</li>
            <li>Optimiert fürs Recruiting der Gen Z</li>
            <li>Bewerbungen mit Qualitätsgarantie</li>
            <li>Flexible Stellenschaltung</li>
            <li>Persönlicher Ansprechpartner</li>
            <li>Zielgerichtete Auslieferung</li>
            <li class="flex row width-100 end gap-sm default-weight-text">
              <i class="mdi mdi-information-slab-circle"></i>
              inkl. Premiumunternehmensprofil
              <div class="info-tooltip">
                {{ primeCompanyTooltipText }}
              </div>
            </li>
            <li class="flex row width-100 end gap-sm default-weight-text">
              <i class="mdi mdi-information-slab-circle"></i>
              inkl. Top Job Platzierung
              <div class="info-tooltip">
                {{ topJobTooltipText }}
              </div>
            </li>
          </ul>
        </div>

        <div class="pricing-options-wrapper" [ngClass]="{'grid': mediaService.getScreenWidth() > 1200}">

          <div class="pricing-option-container-highlighted" *ngIf="mediaService.getScreenWidth() <= 1200">
            <div class="flex column height-100">
              <div class="pricing-option-header flex column gap flex-space-between-center">
                <div class="discount-container">
                  12.5% Rabatt
                </div>
                <div class="flex column center">
                  <h3>Vorteilspaket</h3>
                  <h2>€3.500</h2>
                </div>
              </div>

              <div class="pricing-option-content">
                <ul class="pricing-options-list">
                  <li [ngClass]="{'application-highlight': mediaService.getScreenWidth() > 1200}">
                    <i class="mdi" [ngClass]="{'mdi-file-move': mediaService.getScreenWidth() > 1200, 'mdi-check-circle': mediaService.getScreenWidth() <= 1200}"></i>
                    <div *ngIf="mediaService.getScreenWidth() > 1200">40 Bewerbungen</div>
                    <div *ngIf="mediaService.getScreenWidth() <= 1200">Garantiert <b>40 Bewerbungen</b></div>
                  </li>
                  <li>
                    <i class="mdi mdi-check-circle"></i>
                    <span>Optimiert fürs Recruiting der Gen Z</span>
                  </li>
                  <li>
                    <i class="mdi mdi-check-circle"></i>
                    <span>Bewerbungen mit Qualitätsgarantie</span>
                  </li>
                  <li>
                    <i class="mdi mdi-check-circle"></i>
                    <span>Flexible Stellenschaltung</span>
                  </li>
                  <li>
                    <i class="mdi mdi-check-circle"></i>
                    <span>Persönlicher Ansprechpartner</span>
                  </li>
                  <li>
                    <i class="mdi mdi-check-circle"></i>
                    <span>Zielgerichtete Auslieferung</span>
                  </li>
                  <li>
                    <i class="mdi mdi-check-circle"></i>
                    <div class="flex row start-center gap-sm default-weight-text width-100">
                      <span>inkl. Premiumunternehmensprofil</span>
                      <i class="mdi mdi-information-slab-circle"></i>
                      <div class="info-tooltip">
                        {{ primeCompanyTooltipText }}
                      </div>
                    </div>
                  </li>
                  <li>
                    <i class="mdi mdi-close-circle"></i>
                    <div class="flex row start-center gap-sm default-weight-text width-100">
                      <span>inkl. Top Job Platzierung</span>
                      <i class="mdi mdi-information-slab-circle"></i>
                      <div class="info-tooltip">
                        {{ topJobTooltipText }}
                      </div>
                    </div>
                  </li>
                </ul>

                <button (click)="navToContact('CompanyPageSmallPackage')" class="dark-teal-flat-button margin-bottom">
                  Demo buchen
                </button>
              </div>
            </div>
          </div>

          <div class="grid-item-3 pricing-option-container">
            <div class="flex column height-100">
              <div class="pricing-option-header flex column end">
                <h3>Starterpaket</h3>
                <h2>€1.500</h2>
              </div>

              <div class="pricing-option-content">
                <ul class="pricing-options-list">
                  <li [ngClass]="{'application-highlight': mediaService.getScreenWidth() > 1200}">
                    <i class="mdi" [ngClass]="{'mdi-file-move': mediaService.getScreenWidth() > 1200, 'mdi-check-circle': mediaService.getScreenWidth() <= 1200}"></i>
                    <div *ngIf="mediaService.getScreenWidth() > 1200">15 Bewerbungen</div>
                    <div *ngIf="mediaService.getScreenWidth() <= 1200">Garantiert <b>15 Bewerbungen</b></div>
                  </li>
                  <li>
                    <i class="mdi mdi-check-circle"></i>
                    <span>Optimiert fürs Recruiting der Gen Z</span>
                  </li>
                  <li>
                    <i class="mdi mdi-check-circle"></i>
                    <span>Bewerbungen mit Qualitätsgarantie</span>
                  </li>
                  <li>
                    <i class="mdi mdi-check-circle"></i>
                    <span>Flexible Stellenschaltung</span>
                  </li>
                  <li>
                    <i class="mdi mdi-check-circle"></i>
                    <span>Persönlicher Ansprechpartner</span>
                  </li>
                  <li>
                    <i class="mdi mdi-check-circle"></i>
                    <span>Zielgerichtete Auslieferung</span>
                  </li>
                  <li>
                    <i class="mdi mdi-close-circle"></i>
                    <div class="flex row start-center gap-sm default-weight-text width-100">
                      <span>inkl. Premiumunternehmensprofil</span>
                      <i class="mdi mdi-information-slab-circle"></i>
                      <div class="info-tooltip">
                        {{ primeCompanyTooltipText }}
                      </div>
                    </div>
                  </li>
                  <li>
                    <i class="mdi mdi-close-circle"></i>
                    <div class="flex row start-center gap-sm default-weight-text width-100">
                      <span>inkl. Top Job Platzierung</span>
                      <i class="mdi mdi-information-slab-circle"></i>
                      <div class="info-tooltip">
                        {{ topJobTooltipText }}
                      </div>
                    </div>
                  </li>
                </ul>

                <button (click)="navToContact('CompanyPageSmallPackage')" class="light-stroked-button margin-bottom">
                  Demo buchen
                </button>
              </div>
            </div>
          </div>

          <div class="grid-item-3 pricing-option-container-highlighted" *ngIf="mediaService.getScreenWidth() > 1200">
            <div class="flex column height-100">
              <div class="pricing-option-header flex column gap flex-space-between-center">
                <div class="discount-container">
                  12.5% Rabatt
                </div>
                <div class="flex column center">
                  <h3>Vorteilspaket</h3>
                  <h2>€3.500</h2>
                </div>
              </div>

              <div class="pricing-option-content">
                <ul class="pricing-options-list">
                  <li class="application-highlight">
                    <i class="mdi mdi-file-move"></i>
                    40 Bewerbungen
                  </li>
                  <li><i class="mdi mdi-check-circle"></i></li>
                  <li><i class="mdi mdi-check-circle"></i></li>
                  <li><i class="mdi mdi-check-circle"></i></li>
                  <li><i class="mdi mdi-check-circle"></i></li>
                  <li><i class="mdi mdi-check-circle"></i></li>
                  <li><i class="mdi mdi-check-circle"></i></li>
                  <li><i class="mdi mdi-close-circle"></i></li>
                </ul>

                <button (click)="navToContact('CompanyPageSmallPackage')" class="dark-teal-flat-button margin-bottom">
                  Demo buchen
                </button>
              </div>
            </div>
          </div>

          <div class="grid-item-3 pricing-option-container">
            <div class="flex column height-100">
              <div class="pricing-option-header flex column gap flex-space-between-center">
                <div class="discount-container">
                  25% Rabatt
                </div>
                <div class="flex column center">
                  <h3>Premiumpaket</h3>
                  <h2>€7.500</h2>
                </div>
              </div>

              <div class="pricing-option-content">
                <ul class="pricing-options-list">
                  <li [ngClass]="{'application-highlight': mediaService.getScreenWidth() > 1200}">
                    <i class="mdi" [ngClass]="{'mdi-file-move': mediaService.getScreenWidth() > 1200, 'mdi-check-circle': mediaService.getScreenWidth() <= 1200}"></i>
                    <div *ngIf="mediaService.getScreenWidth() > 1200">100 Bewerbungen</div>
                    <div *ngIf="mediaService.getScreenWidth() <= 1200">Garantiert <b>100 Bewerbungen</b></div>
                  </li>
                  <li>
                    <i class="mdi mdi-check-circle"></i>
                    <span>Optimiert fürs Recruiting der Gen Z</span>
                  </li>
                  <li>
                    <i class="mdi mdi-check-circle"></i>
                    <span>Bewerbungen mit Qualitätsgarantie</span>
                  </li>
                  <li>
                    <i class="mdi mdi-check-circle"></i>
                    <span>Flexible Stellenschaltung</span>
                  </li>
                  <li>
                    <i class="mdi mdi-check-circle"></i>
                    <span>Persönlicher Ansprechpartner</span>
                  </li>
                  <li>
                    <i class="mdi mdi-check-circle"></i>
                    <span>Zielgerichtete Auslieferung</span>
                  </li>
                  <li>
                    <i class="mdi mdi-check-circle"></i>
                    <div class="flex row start-center gap-sm default-weight-text width-100">
                      <span>inkl. Premiumunternehmensprofil</span>
                      <i class="mdi mdi-information-slab-circle"></i>
                      <div class="info-tooltip">
                        {{ primeCompanyTooltipText }}
                      </div>
                    </div>
                  </li>
                  <li>
                    <i class="mdi mdi-check-circle"></i>
                    <div class="flex row start-center gap-sm default-weight-text width-100">
                      <span>inkl. Top Job Platzierung</span>
                      <i class="mdi mdi-information-slab-circle"></i>
                      <div class="info-tooltip">
                        {{ topJobTooltipText }}
                      </div>
                    </div>
                  </li>
                </ul>

                <button (click)="navToContact('CompanyPageSmallPackage')" class="light-stroked-button margin-bottom">
                  Demo buchen
                </button>
              </div>
            </div>
          </div>

          <div class="grid-item-3 pricing-option-container">
            <div class="flex column height-100">
              <div class="pricing-option-header flex column end">
                <h3 class="center-text">Individuelles <br *ngIf="mediaService.getScreenWidth() > 1200"> Paket</h3>
              </div>

              <div class="pricing-option-content">
                <div class="flex column center height-100 gap padding-lg">
                  <i class="mdi mdi-cog"></i>
                  <p class="center-text">
                    Du brauchst mehr Bewerbungen oder hast individuelle Anforderungen?
                  </p>

                  <p class="center-text">
                    Dann sprich mit unserem Team und lass uns gemeinsam die beste Lösung finden.
                  </p>
                </div>

                <button (click)="navToContact('CompanyPageSmallPackage')" class="light-stroked-button margin-bottom">
                  Demo buchen
                </button>
              </div>
            </div>
          </div>

        </div>
      </div>


    </section>


    <contact-formular></contact-formular>

</div>
