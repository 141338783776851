import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {DisplayRecruiterData, JsonWrappedValue, RecruiterData, EmploymentPositionDisplayData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.admin.AdminRecruiterController
 */

@Injectable()
@ResourceParams({})
export class AdminRecruiterResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/companies/recruiters/{id}/activate',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _activateRecruiter: IResourceMethodObservableStrict<null, null, {id: number}, null> | undefined
    activateRecruiter(id: number): Promise<null> {
        if (!this._activateRecruiter) throw new Error("resource has not been properly initialized")
        return this._activateRecruiter(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/companies/recruiters/{recruiterId}/positions/edit',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _assignEmploymentPositionsToRecruiter: IResourceMethodObservableStrict<null, {positionIds: number[]}, {recruiterId: number}, EmploymentPositionDisplayData[]> | undefined
    assignEmploymentPositionsToRecruiter(recruiterId: number, requestParams: {positionIds: number[]}): Promise<EmploymentPositionDisplayData[]> {
        if (!this._assignEmploymentPositionsToRecruiter) throw new Error("resource has not been properly initialized")
        return this._assignEmploymentPositionsToRecruiter(null, requestParams, {recruiterId: recruiterId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/companies/{id}/recruiters',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _createRecruiterForCompany: IResourceMethodObservableStrict<RecruiterData, null, {id: number}, DisplayRecruiterData> | undefined
    createRecruiterForCompany(requestBody: RecruiterData, id: number): Promise<DisplayRecruiterData> {
        if (!this._createRecruiterForCompany) throw new Error("resource has not been properly initialized")
        return this._createRecruiterForCompany(requestBody, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Delete,
        path: '/companies/recruiters',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _deleteRecruiterById: IResourceMethodObservableStrict<number, null, null, null> | undefined
    deleteRecruiterById(requestBody: number): Promise<null> {
        if (!this._deleteRecruiterById) throw new Error("resource has not been properly initialized")
        return this._deleteRecruiterById(requestBody, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/companies/recruiters/{recruiterId}',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _editRecruiter: IResourceMethodObservableStrict<RecruiterData, null, {recruiterId: number}, null> | undefined
    editRecruiter(requestBody: RecruiterData, recruiterId: number): Promise<null> {
        if (!this._editRecruiter) throw new Error("resource has not been properly initialized")
        return this._editRecruiter(requestBody, null, {recruiterId: recruiterId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/recruiters/{mail}/id',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getRecruiterIdByMail: IResourceMethodObservableStrict<null, null, {mail: string}, JsonWrappedValue<number>> | undefined
    getRecruiterIdByMail(mail: string): Promise<JsonWrappedValue<number>> {
        if (!this._getRecruiterIdByMail) throw new Error("resource has not been properly initialized")
        return this._getRecruiterIdByMail(null, null, {mail: mail}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/companies/recruiter/{id}/positions',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getRecruiterPositions: IResourceMethodObservableStrict<null, null, {id: number}, EmploymentPositionDisplayData[]> | undefined
    getRecruiterPositions(id: number): Promise<EmploymentPositionDisplayData[]> {
        if (!this._getRecruiterPositions) throw new Error("resource has not been properly initialized")
        return this._getRecruiterPositions(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/companies/{id}/recruiters',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getRecruitersByCustomerCompanyId: IResourceMethodObservableStrict<null, {showDeleted: boolean}, {id: number}, DisplayRecruiterData[]> | undefined
    getRecruitersByCustomerCompanyId(id: number, requestParams: {showDeleted: boolean}): Promise<DisplayRecruiterData[]> {
        if (!this._getRecruitersByCustomerCompanyId) throw new Error("resource has not been properly initialized")
        return this._getRecruitersByCustomerCompanyId(null, requestParams, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/company/{id}/loginAsAdminRecruiter',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _loginAsAdminRecruiter: IResourceMethodObservableStrict<null, null, {id: number}, JsonWrappedValue<string>> | undefined
    loginAsAdminRecruiter(id: number): Promise<string> {
        if (!this._loginAsAdminRecruiter) throw new Error("resource has not been properly initialized")
        return this._loginAsAdminRecruiter(null, null, {id: id}).toPromise().then((result) => result.value)
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/recruiters/{id}/login',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _loginAsRecruiter: IResourceMethodObservableStrict<null, null, {id: number}, JsonWrappedValue<string>> | undefined
    loginAsRecruiter(id: number): Promise<string> {
        if (!this._loginAsRecruiter) throw new Error("resource has not been properly initialized")
        return this._loginAsRecruiter(null, null, {id: id}).toPromise().then((result) => result.value)
    }

}