import {Component, Input, OnInit} from '@angular/core';
import {WebinarData} from "../../../../generated/data";
import {FileService} from "../../../utility/services/file.service";
import {MediaService} from "../../../utility/services/media.service";

@Component({
  selector: 'app-webinar-card',
  templateUrl: './webinar-card.component.html',
  styleUrl: './webinar-card.component.scss'
})
export class WebinarCardComponent implements OnInit{

  webinarImage: any = null

  @Input() webinarData: WebinarData = <WebinarData>{}
  @Input() size: "Small" | "Medium" | "Large" = "Medium"

  constructor(
    private fileService: FileService,
    public mediaService: MediaService
  ) {
  }

  ngOnInit() {
    let sub = this.fileService.getWebinarImage(this.webinarData.id).subscribe(next => {
      this.webinarImage = URL.createObjectURL(next)
      sub.unsubscribe()
    })
  }

}
