import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {JsonWrappedValue, AdaptCreateConsultantData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.DevelopmentController
 */

@Injectable()
@ResourceParams({})
export class DevelopmentResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/dev/update/groupProfessionRelationsType',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _addProfessionTypeToProfessionSubjectGroupRelations: IResourceMethodObservableStrict<null, null, null, null> | undefined
    addProfessionTypeToProfessionSubjectGroupRelations(): Promise<null> {
        if (!this._addProfessionTypeToProfessionSubjectGroupRelations) throw new Error("resource has not been properly initialized")
        return this._addProfessionTypeToProfessionSubjectGroupRelations(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/dev/blacklist/professions',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _blacklistProfessionsForMatching: IResourceMethodObservableStrict<null, null, null, null> | undefined
    blacklistProfessionsForMatching(): Promise<null> {
        if (!this._blacklistProfessionsForMatching) throw new Error("resource has not been properly initialized")
        return this._blacklistProfessionsForMatching(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/dev/test/selectCompanyQuery',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _checkForInactiveCustomersWithNoLogins: IResourceMethodObservableStrict<null, null, null, null> | undefined
    checkForInactiveCustomersWithNoLogins(): Promise<null> {
        if (!this._checkForInactiveCustomersWithNoLogins) throw new Error("resource has not been properly initialized")
        return this._checkForInactiveCustomersWithNoLogins(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/test/checkStatusOfWhatsAppMessages',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _checkStatusOfWhatsAppMessages: IResourceMethodObservableStrict<null, null, null, null> | undefined
    checkStatusOfWhatsAppMessages(): Promise<null> {
        if (!this._checkStatusOfWhatsAppMessages) throw new Error("resource has not been properly initialized")
        return this._checkStatusOfWhatsAppMessages(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/dev/unseenCount/positions',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _countUnseenApplicationsInPosition: IResourceMethodObservableStrict<null, null, null, null> | undefined
    countUnseenApplicationsInPosition(): Promise<null> {
        if (!this._countUnseenApplicationsInPosition) throw new Error("resource has not been properly initialized")
        return this._countUnseenApplicationsInPosition(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/dev/createAnonymizeTalentsStashForOldTalents',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _createAnonymizeTalentsStashForOldTalents: IResourceMethodObservableStrict<null, null, null, null> | undefined
    createAnonymizeTalentsStashForOldTalents(): Promise<null> {
        if (!this._createAnonymizeTalentsStashForOldTalents) throw new Error("resource has not been properly initialized")
        return this._createAnonymizeTalentsStashForOldTalents(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/dev/api/adapt/coaches',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _createConsultant: IResourceMethodObservableStrict<AdaptCreateConsultantData, null, null, null> | undefined
    createConsultant(requestBody: AdaptCreateConsultantData): Promise<null> {
        if (!this._createConsultant) throw new Error("resource has not been properly initialized")
        return this._createConsultant(requestBody, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/dev/test/createCvIndex/{fileId}',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _createCvIndexForFile: IResourceMethodObservableStrict<null, null, {fileId: number}, null> | undefined
    createCvIndexForFile(fileId: number): Promise<null> {
        if (!this._createCvIndexForFile) throw new Error("resource has not been properly initialized")
        return this._createCvIndexForFile(null, null, {fileId: fileId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/create/onetimetokenlogin/{talentId}',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _createOneTimeTokenLogin: IResourceMethodObservableStrict<null, null, {talentId: number}, null> | undefined
    createOneTimeTokenLogin(talentId: number): Promise<null> {
        if (!this._createOneTimeTokenLogin) throw new Error("resource has not been properly initialized")
        return this._createOneTimeTokenLogin(null, null, {talentId: talentId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/dev/test/unisignCreateLoginLink',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _createUnisignLoginLinkFor: IResourceMethodObservableStrict<null, null, null, null> | undefined
    createUnisignLoginLinkFor(): Promise<null> {
        if (!this._createUnisignLoginLinkFor) throw new Error("resource has not been properly initialized")
        return this._createUnisignLoginLinkFor(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/dev/test/duplicate/deactivate',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _deactivateDuplicateApiJobs: IResourceMethodObservableStrict<null, null, null, null> | undefined
    deactivateDuplicateApiJobs(): Promise<null> {
        if (!this._deactivateDuplicateApiJobs) throw new Error("resource has not been properly initialized")
        return this._deactivateDuplicateApiJobs(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/dev/deleteOldDossierFiles',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _deleteOldDossierFiles: IResourceMethodObservableStrict<null, null, null, null> | undefined
    deleteOldDossierFiles(): Promise<null> {
        if (!this._deleteOldDossierFiles) throw new Error("resource has not been properly initialized")
        return this._deleteOldDossierFiles(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/dev/fix/dkzSubject/names',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _devFixDkzSubjectNames: IResourceMethodObservableStrict<null, null, null, null> | undefined
    devFixDkzSubjectNames(): Promise<null> {
        if (!this._devFixDkzSubjectNames) throw new Error("resource has not been properly initialized")
        return this._devFixDkzSubjectNames(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/dev/test/eventCronosSync',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _eventCronosSync: IResourceMethodObservableStrict<null, null, null, null> | undefined
    eventCronosSync(): Promise<null> {
        if (!this._eventCronosSync) throw new Error("resource has not been properly initialized")
        return this._eventCronosSync(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/dev/test/findDuplicatePositionForStepstone/{jobId}',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _findDuplicatePositionForStepstone: IResourceMethodObservableStrict<null, null, {jobId: number}, null> | undefined
    findDuplicatePositionForStepstone(jobId: number): Promise<null> {
        if (!this._findDuplicatePositionForStepstone) throw new Error("resource has not been properly initialized")
        return this._findDuplicatePositionForStepstone(null, null, {jobId: jobId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/dev/test/duplicate/{jobId}',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _findDuplicatePositionForWorkwise: IResourceMethodObservableStrict<null, null, {jobId: number}, null> | undefined
    findDuplicatePositionForWorkwise(jobId: number): Promise<null> {
        if (!this._findDuplicatePositionForWorkwise) throw new Error("resource has not been properly initialized")
        return this._findDuplicatePositionForWorkwise(null, null, {jobId: jobId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/dev/forceMatchPosition/{id}',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _forceMatchPosition: IResourceMethodObservableStrict<null, null, {id: number}, null> | undefined
    forceMatchPosition(id: number): Promise<null> {
        if (!this._forceMatchPosition) throw new Error("resource has not been properly initialized")
        return this._forceMatchPosition(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/dev/generate/uniwunder/apikey',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _generateUniwunderApiKey: IResourceMethodObservableStrict<null, {companyId: number}, null, null> | undefined
    generateUniwunderApiKey(requestParams: {companyId: number}): Promise<null> {
        if (!this._generateUniwunderApiKey) throw new Error("resource has not been properly initialized")
        return this._generateUniwunderApiKey(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/dev/test/getAllApplicants',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getAllApplicantsOfCompany: IResourceMethodObservableStrict<null, null, null, null> | undefined
    getAllApplicantsOfCompany(): Promise<null> {
        if (!this._getAllApplicantsOfCompany) throw new Error("resource has not been properly initialized")
        return this._getAllApplicantsOfCompany(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/dev/apis/positions/samples',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getApiPositionContentSamples: IResourceMethodObservableStrict<null, null, null, null> | undefined
    getApiPositionContentSamples(): Promise<null> {
        if (!this._getApiPositionContentSamples) throw new Error("resource has not been properly initialized")
        return this._getApiPositionContentSamples(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/dev/test/getCitiesForString/{citys}/{lat}/{log}',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getCitiesForString: IResourceMethodObservableStrict<null, null, {citys: string, log: string, lat: string}, null> | undefined
    getCitiesForString(pathVariables: {citys: string, log: string, lat: string}): Promise<null> {
        if (!this._getCitiesForString) throw new Error("resource has not been properly initialized")
        return this._getCitiesForString(null, null, pathVariables).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/dev/test/{talentId}/coach/crmId',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getCrmIdFromTalentCoach: IResourceMethodObservableStrict<null, null, {talentId: number}, JsonWrappedValue<string>> | undefined
    getCrmIdFromTalentCoach(talentId: number): Promise<JsonWrappedValue<string>> {
        if (!this._getCrmIdFromTalentCoach) throw new Error("resource has not been properly initialized")
        return this._getCrmIdFromTalentCoach(null, null, {talentId: talentId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/dev/test/getEmploymentPositionById/{id}',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getEmploymentPositionById: IResourceMethodObservableStrict<null, null, {id: number}, null> | undefined
    getEmploymentPositionById(id: number): Promise<null> {
        if (!this._getEmploymentPositionById) throw new Error("resource has not been properly initialized")
        return this._getEmploymentPositionById(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/dev/controlling/partner/xssf',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getEveryPossiblePrimeTalent: IResourceMethodObservableStrict<null, null, null, Blob> | undefined
    getEveryPossiblePrimeTalent(): Promise<Blob> {
        if (!this._getEveryPossiblePrimeTalent) throw new Error("resource has not been properly initialized")
        return this._getEveryPossiblePrimeTalent(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/dev/getPerfectMatchEventsFromCronos',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getPerfectMatchEventsFromCronos: IResourceMethodObservableStrict<null, null, null, null> | undefined
    getPerfectMatchEventsFromCronos(): Promise<null> {
        if (!this._getPerfectMatchEventsFromCronos) throw new Error("resource has not been properly initialized")
        return this._getPerfectMatchEventsFromCronos(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/dev/talents/{id}/dossier',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getTalentDossier: IResourceMethodObservableStrict<null, null, {id: number}, Blob> | undefined
    getTalentDossier(id: number): Promise<Blob> {
        if (!this._getTalentDossier) throw new Error("resource has not been properly initialized")
        return this._getTalentDossier(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/dev/import/dkzSubjects',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _importDkzSubjects: IResourceMethodObservableStrict<null, null, null, null> | undefined
    importDkzSubjects(): Promise<null> {
        if (!this._importDkzSubjects) throw new Error("resource has not been properly initialized")
        return this._importDkzSubjects(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/test/api/import',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _importFromAPI: IResourceMethodObservableStrict<null, null, null, null> | undefined
    importFromAPI(): Promise<null> {
        if (!this._importFromAPI) throw new Error("resource has not been properly initialized")
        return this._importFromAPI(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/dev/import/workfields/mapping',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _importProfessionalFieldsOfActivitySubjectGroupMapping: IResourceMethodObservableStrict<null, null, null, null> | undefined
    importProfessionalFieldsOfActivitySubjectGroupMapping(): Promise<null> {
        if (!this._importProfessionalFieldsOfActivitySubjectGroupMapping) throw new Error("resource has not been properly initialized")
        return this._importProfessionalFieldsOfActivitySubjectGroupMapping(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/dev/test/insertNewCity',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _insertNewCity: IResourceMethodObservableStrict<null, null, null, null> | undefined
    insertNewCity(): Promise<null> {
        if (!this._insertNewCity) throw new Error("resource has not been properly initialized")
        return this._insertNewCity(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/dev/map/professions',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _mapProfessionsWithoutSGMappingsToSgsBasedOnTitleSimilarity: IResourceMethodObservableStrict<null, null, null, null> | undefined
    mapProfessionsWithoutSGMappingsToSgsBasedOnTitleSimilarity(): Promise<null> {
        if (!this._mapProfessionsWithoutSGMappingsToSgsBasedOnTitleSimilarity) throw new Error("resource has not been properly initialized")
        return this._mapProfessionsWithoutSGMappingsToSgsBasedOnTitleSimilarity(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/dev/matchAllPositions',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _matchAllPositions: IResourceMethodObservableStrict<null, null, null, null> | undefined
    matchAllPositions(): Promise<null> {
        if (!this._matchAllPositions) throw new Error("resource has not been properly initialized")
        return this._matchAllPositions(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/dev/test/matchPositionAndTalentPrimefilter/{userId}/{positionId}',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _matchTalentAndPositionPrimeFilter: IResourceMethodObservableStrict<null, null, {userId: number, positionId: number}, null> | undefined
    matchTalentAndPositionPrimeFilter(pathVariables: {userId: number, positionId: number}): Promise<null> {
        if (!this._matchTalentAndPositionPrimeFilter) throw new Error("resource has not been properly initialized")
        return this._matchTalentAndPositionPrimeFilter(null, null, pathVariables).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/dev/test/matchTalentsOnPrimeFiltersForPrimeProcess',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _matchTalentsOnPrimeFiltersForPrimeProcess: IResourceMethodObservableStrict<null, null, null, null> | undefined
    matchTalentsOnPrimeFiltersForPrimeProcess(): Promise<null> {
        if (!this._matchTalentsOnPrimeFiltersForPrimeProcess) throw new Error("resource has not been properly initialized")
        return this._matchTalentsOnPrimeFiltersForPrimeProcess(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/dev/normalize/dkz/subjects',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _normalizeDkzSubjectNames: IResourceMethodObservableStrict<null, null, null, null> | undefined
    normalizeDkzSubjectNames(): Promise<null> {
        if (!this._normalizeDkzSubjectNames) throw new Error("resource has not been properly initialized")
        return this._normalizeDkzSubjectNames(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/dev/test/job/parseAndMatchApiJobs',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _parseAndMatchApiJobs: IResourceMethodObservableStrict<null, null, null, null> | undefined
    parseAndMatchApiJobs(): Promise<null> {
        if (!this._parseAndMatchApiJobs) throw new Error("resource has not been properly initialized")
        return this._parseAndMatchApiJobs(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/dev/print/apiJobCounts',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _printApiJobCounts: IResourceMethodObservableStrict<null, null, null, null> | undefined
    printApiJobCounts(): Promise<null> {
        if (!this._printApiJobCounts) throw new Error("resource has not been properly initialized")
        return this._printApiJobCounts(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/dev/pushAdaptRequests',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _pushAdaptRequests: IResourceMethodObservableStrict<null, null, null, null> | undefined
    pushAdaptRequests(): Promise<null> {
        if (!this._pushAdaptRequests) throw new Error("resource has not been properly initialized")
        return this._pushAdaptRequests(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/dev/regroup/dkz',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _reGroupDkzSubjectAndGroupMappings: IResourceMethodObservableStrict<null, null, null, null> | undefined
    reGroupDkzSubjectAndGroupMappings(): Promise<null> {
        if (!this._reGroupDkzSubjectAndGroupMappings) throw new Error("resource has not been properly initialized")
        return this._reGroupDkzSubjectAndGroupMappings(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/dev/rebuildGeoIndex',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _rebuildGeoIndex: IResourceMethodObservableStrict<null, null, null, null> | undefined
    rebuildGeoIndex(): Promise<null> {
        if (!this._rebuildGeoIndex) throw new Error("resource has not been properly initialized")
        return this._rebuildGeoIndex(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/dev/caches/matching/rebuild',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _rebuildMatchingCache: IResourceMethodObservableStrict<null, null, null, null> | undefined
    rebuildMatchingCache(): Promise<null> {
        if (!this._rebuildMatchingCache) throw new Error("resource has not been properly initialized")
        return this._rebuildMatchingCache(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/dev/test/recalculateJaccardScores',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _reevaluateJaccardScores: IResourceMethodObservableStrict<null, null, null, null> | undefined
    reevaluateJaccardScores(): Promise<null> {
        if (!this._reevaluateJaccardScores) throw new Error("resource has not been properly initialized")
        return this._reevaluateJaccardScores(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/rematch/prime/talents',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _rematchPrimeTalents: IResourceMethodObservableStrict<null, null, null, null> | undefined
    rematchPrimeTalents(): Promise<null> {
        if (!this._rematchPrimeTalents) throw new Error("resource has not been properly initialized")
        return this._rematchPrimeTalents(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/dev/remove/experteer',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _removeActiveExperteerPositions: IResourceMethodObservableStrict<null, null, null, null> | undefined
    removeActiveExperteerPositions(): Promise<null> {
        if (!this._removeActiveExperteerPositions) throw new Error("resource has not been properly initialized")
        return this._removeActiveExperteerPositions(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/dev/test/resendFailedSms',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _resendFailedSms: IResourceMethodObservableStrict<null, null, null, null> | undefined
    resendFailedSms(): Promise<null> {
        if (!this._resendFailedSms) throw new Error("resource has not been properly initialized")
        return this._resendFailedSms(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/test/sendCoachPositionRecommendationMessage',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _sendCoachPositionRecommendationMessage: IResourceMethodObservableStrict<null, null, null, null> | undefined
    sendCoachPositionRecommendationMessage(): Promise<null> {
        if (!this._sendCoachPositionRecommendationMessage) throw new Error("resource has not been properly initialized")
        return this._sendCoachPositionRecommendationMessage(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/dev/test/eventMail',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _sendEventMailToTalents: IResourceMethodObservableStrict<null, null, null, null> | undefined
    sendEventMailToTalents(): Promise<null> {
        if (!this._sendEventMailToTalents) throw new Error("resource has not been properly initialized")
        return this._sendEventMailToTalents(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/dev/sendRecommFunnel',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _sendRecommendationStash: IResourceMethodObservableStrict<null, null, null, null> | undefined
    sendRecommendationStash(): Promise<null> {
        if (!this._sendRecommendationStash) throw new Error("resource has not been properly initialized")
        return this._sendRecommendationStash(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/dev/test/adaptActivityPush',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _testAdaptActivityPush: IResourceMethodObservableStrict<null, null, null, null> | undefined
    testAdaptActivityPush(): Promise<null> {
        if (!this._testAdaptActivityPush) throw new Error("resource has not been properly initialized")
        return this._testAdaptActivityPush(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/dev/test/addRecruiterToChat',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _testAddRecruiterToChat: IResourceMethodObservableStrict<null, null, null, null> | undefined
    testAddRecruiterToChat(): Promise<null> {
        if (!this._testAddRecruiterToChat) throw new Error("resource has not been properly initialized")
        return this._testAddRecruiterToChat(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/test/anon',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _testAnon: IResourceMethodObservableStrict<null, null, null, null> | undefined
    testAnon(): Promise<null> {
        if (!this._testAnon) throw new Error("resource has not been properly initialized")
        return this._testAnon(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/dev/test/anonymize/talents',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _testAnonymizeTalents: IResourceMethodObservableStrict<null, null, null, null> | undefined
    testAnonymizeTalents(): Promise<null> {
        if (!this._testAnonymizeTalents) throw new Error("resource has not been properly initialized")
        return this._testAnonymizeTalents(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/dev/test/anonymize/scheduler',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _testAnonymizeTalents2: IResourceMethodObservableStrict<null, null, null, null> | undefined
    testAnonymizeTalents2(): Promise<null> {
        if (!this._testAnonymizeTalents2) throw new Error("resource has not been properly initialized")
        return this._testAnonymizeTalents2(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/dev/test/job/import',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _testApiJobImport: IResourceMethodObservableStrict<null, null, null, null> | undefined
    testApiJobImport(): Promise<null> {
        if (!this._testApiJobImport) throw new Error("resource has not been properly initialized")
        return this._testApiJobImport(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/dev/test/apiJobWorker',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _testApiJobMatchingWorker: IResourceMethodObservableStrict<null, null, null, null> | undefined
    testApiJobMatchingWorker(): Promise<null> {
        if (!this._testApiJobMatchingWorker) throw new Error("resource has not been properly initialized")
        return this._testApiJobMatchingWorker(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/dev/test/job/reactivation',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _testApiJobReactivation: IResourceMethodObservableStrict<null, null, null, null> | undefined
    testApiJobReactivation(): Promise<null> {
        if (!this._testApiJobReactivation) throw new Error("resource has not been properly initialized")
        return this._testApiJobReactivation(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/dev/test/autoRec/{talentId}',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _testAutoRecommentdationForTalent: IResourceMethodObservableStrict<null, null, {talentId: number}, null> | undefined
    testAutoRecommentdationForTalent(talentId: number): Promise<null> {
        if (!this._testAutoRecommentdationForTalent) throw new Error("resource has not been properly initialized")
        return this._testAutoRecommentdationForTalent(null, null, {talentId: talentId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/dev/test/bookmarkFunnel',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _testBookmarkFunnel: IResourceMethodObservableStrict<null, null, null, null> | undefined
    testBookmarkFunnel(): Promise<null> {
        if (!this._testBookmarkFunnel) throw new Error("resource has not been properly initialized")
        return this._testBookmarkFunnel(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/dev/company/chat',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _testChatMessageQuery: IResourceMethodObservableStrict<null, null, null, null> | undefined
    testChatMessageQuery(): Promise<null> {
        if (!this._testChatMessageQuery) throw new Error("resource has not been properly initialized")
        return this._testChatMessageQuery(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/dev/test/coachAssignedSms',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _testCoachAssignedSms: IResourceMethodObservableStrict<null, null, null, null> | undefined
    testCoachAssignedSms(): Promise<null> {
        if (!this._testCoachAssignedSms) throw new Error("resource has not been properly initialized")
        return this._testCoachAssignedSms(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/dev/test/deactivatePositions',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _testDeactivatePositions: IResourceMethodObservableStrict<null, null, null, null> | undefined
    testDeactivatePositions(): Promise<null> {
        if (!this._testDeactivatePositions) throw new Error("resource has not been properly initialized")
        return this._testDeactivatePositions(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Delete,
        path: '/dev/test/talents',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _testDeleteTestAccounts: IResourceMethodObservableStrict<null, null, null, null> | undefined
    testDeleteTestAccounts(): Promise<null> {
        if (!this._testDeleteTestAccounts) throw new Error("resource has not been properly initialized")
        return this._testDeleteTestAccounts(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/dev/test/testRequest',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _testElasticSearchRequest: IResourceMethodObservableStrict<null, null, null, null> | undefined
    testElasticSearchRequest(): Promise<null> {
        if (!this._testElasticSearchRequest) throw new Error("resource has not been properly initialized")
        return this._testElasticSearchRequest(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/dev/test/existsUnseenRecommendations',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _testExistsUnseenRecommendationsQuery: IResourceMethodObservableStrict<null, null, null, null> | undefined
    testExistsUnseenRecommendationsQuery(): Promise<null> {
        if (!this._testExistsUnseenRecommendationsQuery) throw new Error("resource has not been properly initialized")
        return this._testExistsUnseenRecommendationsQuery(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/dev/test/resendFailedApplicationMails',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _testFailedSmtpApplicationMails: IResourceMethodObservableStrict<null, null, null, null> | undefined
    testFailedSmtpApplicationMails(): Promise<null> {
        if (!this._testFailedSmtpApplicationMails) throw new Error("resource has not been properly initialized")
        return this._testFailedSmtpApplicationMails(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/dev/test/autoRec/first',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _testFirstAutoRecommentdation: IResourceMethodObservableStrict<null, null, null, null> | undefined
    testFirstAutoRecommentdation(): Promise<null> {
        if (!this._testFirstAutoRecommentdation) throw new Error("resource has not been properly initialized")
        return this._testFirstAutoRecommentdation(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/dev/test/googleOC/{talentId}',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _testGoogleOCPush: IResourceMethodObservableStrict<null, null, {talentId: number}, null> | undefined
    testGoogleOCPush(talentId: number): Promise<null> {
        if (!this._testGoogleOCPush) throw new Error("resource has not been properly initialized")
        return this._testGoogleOCPush(null, null, {talentId: talentId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/dev/test/isco',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _testISCOMappingImport: IResourceMethodObservableStrict<null, null, null, null> | undefined
    testISCOMappingImport(): Promise<null> {
        if (!this._testISCOMappingImport) throw new Error("resource has not been properly initialized")
        return this._testISCOMappingImport(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/dev/test/jaccard/{jobId}',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _testJaccard: IResourceMethodObservableStrict<null, null, {jobId: number}, null> | undefined
    testJaccard(jobId: number): Promise<null> {
        if (!this._testJaccard) throw new Error("resource has not been properly initialized")
        return this._testJaccard(null, null, {jobId: jobId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Delete,
        path: '/dev/test/legacyCv/delete',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _testLegacyCvDeletion: IResourceMethodObservableStrict<null, null, null, null> | undefined
    testLegacyCvDeletion(): Promise<null> {
        if (!this._testLegacyCvDeletion) throw new Error("resource has not been properly initialized")
        return this._testLegacyCvDeletion(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/dev/test/email',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _testMailjetEmail: IResourceMethodObservableStrict<null, null, null, null> | undefined
    testMailjetEmail(): Promise<null> {
        if (!this._testMailjetEmail) throw new Error("resource has not been properly initialized")
        return this._testMailjetEmail(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/dev/test/sms',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _testMailjetSms: IResourceMethodObservableStrict<null, null, null, null> | undefined
    testMailjetSms(): Promise<null> {
        if (!this._testMailjetSms) throw new Error("resource has not been properly initialized")
        return this._testMailjetSms(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/dev/testFB/offlineConversions',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _testOfflineConversion: IResourceMethodObservableStrict<null, null, null, null> | undefined
    testOfflineConversion(): Promise<null> {
        if (!this._testOfflineConversion) throw new Error("resource has not been properly initialized")
        return this._testOfflineConversion(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/dev/test/password/reset/{userId}',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _testPasswordResetLink: IResourceMethodObservableStrict<null, null, {userId: number}, null> | undefined
    testPasswordResetLink(userId: number): Promise<null> {
        if (!this._testPasswordResetLink) throw new Error("resource has not been properly initialized")
        return this._testPasswordResetLink(null, null, {userId: userId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/dev/test/unseenChatsPerformance',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _testPerformanceOfUnseenChats: IResourceMethodObservableStrict<null, null, null, null> | undefined
    testPerformanceOfUnseenChats(): Promise<null> {
        if (!this._testPerformanceOfUnseenChats) throw new Error("resource has not been properly initialized")
        return this._testPerformanceOfUnseenChats(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/dev/test/hybrid/matchingReports',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _testPersistHybridSearchMatchingReports: IResourceMethodObservableStrict<null, null, null, null> | undefined
    testPersistHybridSearchMatchingReports(): Promise<null> {
        if (!this._testPersistHybridSearchMatchingReports) throw new Error("resource has not been properly initialized")
        return this._testPersistHybridSearchMatchingReports(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/dev/test/prepareWaitingPositions',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _testPrepareWaitingPositions: IResourceMethodObservableStrict<null, null, null, null> | undefined
    testPrepareWaitingPositions(): Promise<null> {
        if (!this._testPrepareWaitingPositions) throw new Error("resource has not been properly initialized")
        return this._testPrepareWaitingPositions(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/dev/test/recommendationTest',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _testRecommendationFunnel: IResourceMethodObservableStrict<null, null, null, null> | undefined
    testRecommendationFunnel(): Promise<null> {
        if (!this._testRecommendationFunnel) throw new Error("resource has not been properly initialized")
        return this._testRecommendationFunnel(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/dev/test/recommendationMessage/{userId}',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _testRecommendationMessage: IResourceMethodObservableStrict<null, null, {userId: number}, null> | undefined
    testRecommendationMessage(userId: number): Promise<null> {
        if (!this._testRecommendationMessage) throw new Error("resource has not been properly initialized")
        return this._testRecommendationMessage(null, null, {userId: userId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/dev/primerecomms/test',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _testRecomms: IResourceMethodObservableStrict<null, null, null, null> | undefined
    testRecomms(): Promise<null> {
        if (!this._testRecomms) throw new Error("resource has not been properly initialized")
        return this._testRecomms(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/dev/test/newWeeklyMessagesTest',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _testReminderChatMessagesFunnel: IResourceMethodObservableStrict<null, null, null, null> | undefined
    testReminderChatMessagesFunnel(): Promise<null> {
        if (!this._testReminderChatMessagesFunnel) throw new Error("resource has not been properly initialized")
        return this._testReminderChatMessagesFunnel(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/dev/test/testSetFieldOfActivities',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _testSetFieldOfActivities: IResourceMethodObservableStrict<null, null, null, null> | undefined
    testSetFieldOfActivities(): Promise<null> {
        if (!this._testSetFieldOfActivities) throw new Error("resource has not been properly initialized")
        return this._testSetFieldOfActivities(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/dev/test/hasEventReg/{id}',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _testTalentHasActiveEventReg: IResourceMethodObservableStrict<null, null, {id: number}, JsonWrappedValue<boolean>> | undefined
    testTalentHasActiveEventReg(id: number): Promise<boolean> {
        if (!this._testTalentHasActiveEventReg) throw new Error("resource has not been properly initialized")
        return this._testTalentHasActiveEventReg(null, null, {id: id}).toPromise().then((result) => result.value)
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/dev/test/twilio',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _testTwilioIntegration: IResourceMethodObservableStrict<null, null, null, null> | undefined
    testTwilioIntegration(): Promise<null> {
        if (!this._testTwilioIntegration) throw new Error("resource has not been properly initialized")
        return this._testTwilioIntegration(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/dev/test/update/ratings',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _testUpdateRatings: IResourceMethodObservableStrict<null, null, null, null> | undefined
    testUpdateRatings(): Promise<null> {
        if (!this._testUpdateRatings) throw new Error("resource has not been properly initialized")
        return this._testUpdateRatings(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/dev/test/workwiseDuplicates/{ct}',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _testWorkwiseDuplicates: IResourceMethodObservableStrict<null, null, {ct: string}, null> | undefined
    testWorkwiseDuplicates(ct: string): Promise<null> {
        if (!this._testWorkwiseDuplicates) throw new Error("resource has not been properly initialized")
        return this._testWorkwiseDuplicates(null, null, {ct: ct}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/dev/schedulers/pauseMatching',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _triggerPauseMatchingScheduler: IResourceMethodObservableStrict<null, null, null, null> | undefined
    triggerPauseMatchingScheduler(): Promise<null> {
        if (!this._triggerPauseMatchingScheduler) throw new Error("resource has not been properly initialized")
        return this._triggerPauseMatchingScheduler(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/dev/test/trigger/matchingCompleted',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _triggerUpdateReportsForCompletedMatches: IResourceMethodObservableStrict<null, null, null, null> | undefined
    triggerUpdateReportsForCompletedMatches(): Promise<null> {
        if (!this._triggerUpdateReportsForCompletedMatches) throw new Error("resource has not been properly initialized")
        return this._triggerUpdateReportsForCompletedMatches(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/dev/test/updateAllInElastic',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _updateAllPostitionsElastic: IResourceMethodObservableStrict<null, null, null, null> | undefined
    updateAllPostitionsElastic(): Promise<null> {
        if (!this._updateAllPostitionsElastic) throw new Error("resource has not been properly initialized")
        return this._updateAllPostitionsElastic(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/dev/test/updateApplicationDossiers',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _updateApplicationDossiers: IResourceMethodObservableStrict<null, null, null, null> | undefined
    updateApplicationDossiers(): Promise<null> {
        if (!this._updateApplicationDossiers) throw new Error("resource has not been properly initialized")
        return this._updateApplicationDossiers(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/dev/test/updateInElastic/{posId}',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _updatePostitionElastic: IResourceMethodObservableStrict<null, null, {posId: number}, null> | undefined
    updatePostitionElastic(posId: number): Promise<null> {
        if (!this._updatePostitionElastic) throw new Error("resource has not been properly initialized")
        return this._updatePostitionElastic(null, null, {posId: posId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/dev/update/talentFilters/dkz',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _updateTalentFiltersForDkzMapping: IResourceMethodObservableStrict<null, null, null, null> | undefined
    updateTalentFiltersForDkzMapping(): Promise<null> {
        if (!this._updateTalentFiltersForDkzMapping) throw new Error("resource has not been properly initialized")
        return this._updateTalentFiltersForDkzMapping(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/dev/test/validatePhonenumber/talents/{talentId}',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _validatePhonenumber: IResourceMethodObservableStrict<null, null, {talentId: number}, null> | undefined
    validatePhonenumber(talentId: number): Promise<null> {
        if (!this._validatePhonenumber) throw new Error("resource has not been properly initialized")
        return this._validatePhonenumber(null, null, {talentId: talentId}).toPromise()
    }

}