import {Component, OnInit} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Router} from "@angular/router";
import {environment} from "../../../environments/environment";
import {MediaService} from "../../utility/services/media.service";
import {CareerArticleData} from "../../../generated/hid/data";
import {PublicCareerArticleResource} from "../../../generated/hid/resources";
import {AnimationsService} from "../../utility/services/animations.service";
import {LocalStorageService} from "../../utility/services/local-storage.service";
import {MatDialog} from "@angular/material/dialog";
import {AnalyticsService} from "../../utility/services/gdprconsent/analytics.service";
import {PagedData} from "../../../generated/data";
import {UtmService} from "../../utility/services/utm.service";

@Component({
  selector: 'app-talent-page',
  templateUrl: './talent-page.component.html',
  styleUrl: './talent-page.component.scss'
})
export class TalentPageComponent implements OnInit{

  displayJobs: EmploymentPositionData[] = []
  careerGuides: CareerArticleData[] = []
  totalJobsAmount: any

  constructor(
    private router: Router,
    private analyticsService: AnalyticsService,
    private http: HttpClient,
    public mediaService: MediaService,
    private guidesResource: PublicCareerArticleResource,
    private animationsService: AnimationsService,
    private localStorageService: LocalStorageService,
    private dialog: MatDialog,
    private utmService: UtmService
    ) {
  }

  ngOnInit() {
    this.handleScrollAnimations()
    this.getJobsForLandingPage()
    this.getTalentagentEmploymentPositionCount()
    this.loadCareerGuides()
  }

  getJobsForLandingPage() {
    this.http.get( environment.apiUrl + '/public/landingPageJobs', {
      headers: new HttpHeaders(),
      responseType: 'json',
      observe: 'body'
    }).subscribe((next: any) => {
      this.displayJobs = next['content']
    })
  }

  getTalentagentEmploymentPositionCount() {
    this.http.get(`${environment.apiUrl}/public/positions/count`, {
      headers: new HttpHeaders(),
      responseType: 'json',
      observe: 'body'
    }).subscribe((next: any) => {
      this.totalJobsAmount = next['value']
    })
  }

  loadCareerGuides() {
    this.guidesResource.getAllArticles({page: 0, pageSize: 3}).then((result: PagedData<CareerArticleData>) => {
      this.careerGuides = result.content
    })
  }

  handleLogin() {

  }

  handleScrollAnimations(){

    this.animationsService.handleOnScrollAnimation('.hidden', 'show', true)
    this.animationsService.handleOnScrollAnimation('.step-text', 'slideInBottom', true)
    this.animationsService.handleOnScrollAnimation('.usp', 'bounceIn', false)
    this.animationsService.handleOnScrollAnimation('.jobs', 'jobSlide', false)
    this.animationsService.handleOnScrollAnimation('.arrow', 'revealFromTop', false)
    this.animationsService.handleOnScrollAnimation('.highlight', 'highlight-show', false)
    this.animationsService.handleOnScrollAnimation('.partner-logo', 'slideInLeft', true)
    this.animationsService.handleOnScrollAnimation('.guides', 'jobSlide', false)
    this.animationsService.handleOnScrollAnimation('.appearCenterToTopRight', 'centerToTopRightAnimation', false)
    this.animationsService.handleOnScrollAnimation('.appearCenterToBottomLeft', 'centerToBottomLeftAnimation', false)
    this.animationsService.handleOnScrollAnimation('.cv-img', 'slideInBottom', false)
    this.animationsService.handleOnScrollAnimation('.magnify-icon', 'rotateInWithSpin', false)


    this.animationsService.handleOnScrollAnimation('.big-sparkles', 'centerToTopRightAnimation', true)
    this.animationsService.handleOnScrollAnimation('.little-sparkles', 'centerToBottomLeftAnimation', true)
    this.animationsService.handleOnScrollAnimation('.top-match-pseudo-button', 'colorChangeAnimation', true)
    this.animationsService.handleOnScrollAnimation('.click-hand', 'cursorClickAnimation', true)


    this.animationsService.handleOnScrollAnimation('.file-marine', 'slideInBottom', true)
    this.animationsService.handleOnScrollAnimation('.cancel-teal', 'rotateInWithSpin', true)
  }

  routeToTalentAgent() {
    this.analyticsService.reportCheckoutTrackingEvent('InitiateCheckout', null)
    window.open(environment.talentAgentFrontendUrl +  '/onboarding' + this.utmService.getUtmParamsAsString())
  }

  openMlpInformationDialog() {
    //this.dialog.open(MlpInformationDialogComponent,{ panelClass: 'full-width-mobile-dialog'})
    window.open('https://mlp-se.de/unternehmensprofil/')
  }

}

export interface EmploymentPositionData {
  hiringCompanyName: string,
  id: number,
  locationDescription: string,
  title: string
}
