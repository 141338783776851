import { Injectable } from '@angular/core';
import {UtmService} from "../../utility/services/utm.service";
import {AnalyticsService} from "../../utility/services/gdprconsent/analytics.service";
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class DemodeskService {

    context: string = ""
  constructor(
      private utmService: UtmService,
      private analyticsService: AnalyticsService,
      private router: Router
  ) { }

    navToContactForm(context: string) {
        this.context = context
        this.analyticsService.reportDemoRequest(context)
        document.getElementById("contactSection")?.scrollIntoView({behavior: "smooth"})
    }
}
