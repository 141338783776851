import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {DemoBookingComponent} from "./demo-booking.component";



@NgModule({
  declarations: [
    DemoBookingComponent
  ],
  imports: [
    CommonModule
  ]
})
export class DemoBookingModule { }
