import {NgModule} from '@angular/core';
import {RouterModule, Routes} from "@angular/router";
import {TalentPageComponent} from "./general/talent-page/talent-page.component";
import {CareerGuideComponent} from "./general/career-guide/career-guide.component";
import {CareerGuideDetailComponent} from "./general/career-guide/career-guide-detail/career-guide-detail.component";
import {CompanyPageComponent} from "./general/company-page/company-page.component";
import {CaseStudiesPageComponent} from "./general/case-studies/case-studies-page/case-studies-page.component";
import {SingleCaseStudyComponent} from "./general/case-studies/single-case-study/single-case-study.component";
import {CareerGuideAllComponent} from "./general/career-guide/career-guide-all/career-guide-all.component";
import {DemoBookingComponent} from "./general/demo-booking/demo-booking.component";
import {LandingPagesComponent} from "./general/landing-pages/landing-pages.component";
import {WebinarDetailComponent} from "./general/webinar/webinar-detail/webinar-detail.component";
import {LegalPrivacyComponent} from "./general/legal/legal-privacy/legal-privacy.component";
import {LegalImprintComponent} from "./general/legal/legal-imprint/legal-imprint.component";
import {PositionDetailsComponent} from "./general/position-details/position-details/position-details.component";
import {LegalUseRegulationsComponent} from "./general/legal/legal-use-regulations/legal-use-regulations.component";

const routes: Routes = [
  {path: 'talent', component: TalentPageComponent},
  {path: 'company', component: CompanyPageComponent},
  {path: 'karriereguide', component: CareerGuideComponent},
  {path: 'karriereguide/all', component: CareerGuideAllComponent},
  {path: 'karriereguide/:routingUrl', component: CareerGuideDetailComponent},
  {path: 'caseStudies', component: CaseStudiesPageComponent},
  {path: 'caseStudies/:routingUrl', component: SingleCaseStudyComponent},
  {path: 'demo-booking', component: DemoBookingComponent},
  {path: 'tp/:routingUrl', component: LandingPagesComponent},
 // {path: 'about', component: AboutPageComponent},
  {path: 'datenschutz', component: LegalPrivacyComponent},
    {path: "privacy", redirectTo: "/datenschutz"},
    {path: "use-regulations", component: LegalUseRegulationsComponent},
  {path: 'imprint', component: LegalImprintComponent},
  {path: 'webinar/:routingUrl', component: WebinarDetailComponent},
  {path: 'position-details/:positionId', component: PositionDetailsComponent},
  {path: '**', redirectTo: 'talent'}  // Wildcard route should be last
]


@NgModule({
  declarations: [],
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports : [RouterModule]
})
export class AppRoutingModule { }
