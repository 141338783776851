import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {BookingData, BookingFilterData, PagedData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.admin.AdminBookingController
 */

@Injectable()
@ResourceParams({})
export class AdminBookingResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/admin/bookings/{id}/close',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _closeBooking: IResourceMethodObservableStrict<null, null, {id: number}, null> | undefined
    closeBooking(id: number): Promise<null> {
        if (!this._closeBooking) throw new Error("resource has not been properly initialized")
        return this._closeBooking(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/admin/bookings/create',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _createOrUpdateBooking: IResourceMethodObservableStrict<BookingData, {dateFrom: Date}, null, BookingData> | undefined
    createOrUpdateBooking(requestBody: BookingData, requestParams: {dateFrom: Date}): Promise<BookingData> {
        if (!this._createOrUpdateBooking) throw new Error("resource has not been properly initialized")
        return this._createOrUpdateBooking(requestBody, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/admin/bookings/filter',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getFilteredBookings: IResourceMethodObservableStrict<BookingFilterData, {pageNum?: number, pageSize?: number}, null, PagedData<BookingData>> | undefined
    getFilteredBookings(requestBody: BookingFilterData, requestParams?: {pageNum?: number, pageSize?: number}): Promise<PagedData<BookingData>> {
        if (!this._getFilteredBookings) throw new Error("resource has not been properly initialized")
        return this._getFilteredBookings(requestBody, requestParams || {}, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/admin/bookings/{id}/pause',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _pauseBooking: IResourceMethodObservableStrict<null, null, {id: number}, null> | undefined
    pauseBooking(id: number): Promise<null> {
        if (!this._pauseBooking) throw new Error("resource has not been properly initialized")
        return this._pauseBooking(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/admin/bookings/{id}/reactivate',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _reactivateBooking: IResourceMethodObservableStrict<null, null, {id: number}, null> | undefined
    reactivateBooking(id: number): Promise<null> {
        if (!this._reactivateBooking) throw new Error("resource has not been properly initialized")
        return this._reactivateBooking(null, null, {id: id}).toPromise()
    }

}