<div class="flex column center gap-xl content-padding">
    <div class="content-width card-shadow">


        <h1>Datenschutzerklärung</h1>
        <h1>1. Einleitung</h1>
        Mit den folgenden Informationen möchten wir Ihnen als "betroffener Person" einen Überblick über die Verarbeitung
        Ihrer personenbezogenen Daten durch uns und Ihre Rechte aus den Datenschutzgesetzen geben. Eine Nutzung unserer
        Internetseiten ist grundsätzlich ohne Eingabe personenbezogener Daten möglich. Sofern Sie besondere Services
        unseres Unternehmens über unsere Internetseite in Anspruch nehmen möchten, könnte jedoch eine Verarbeitung
        personenbezogener Daten erforderlich werden. Ist die Verarbeitung personenbezogener Daten erforderlich und
        besteht für eine solche Verarbeitung keine gesetzliche Grundlage, holen wir generell eine Einwilligung von Ihnen
        ein.
        <br>
        <br>
        Die Verarbeitung von personenbezogenen Daten, beispielsweise Ihres Namens, der Anschrift oder E-Mail-Adresse,
        erfolgt stets im Einklang mit der Datenschutz-Grundverordnung (DS-GVO) und in Übereinstimmung mit den für die
        "Uniwunder GmbH" geltenden landesspezifischen Datenschutzbestimmungen. Mittels dieser Datenschutzerklärung
        möchten wir Sie über Umfang und Zweck der von uns erhobenen, genutzten und verarbeiteten personenbezogenen Daten
        informieren.
        <br>
        <br>
        Wir haben als für die Verarbeitung Verantwortliche zahlreiche technische und organisatorische Maßnahmen
        umgesetzt, um einen möglichst lückenlosen Schutz der über diese Internetseite verarbeiteten personenbezogenen
        Daten sicherzustellen. Dennoch können internetbasierte Datenübertragungen grundsätzlich Sicherheitslücken
        aufweisen, sodass ein absoluter Schutz nicht gewährleistet werden kann. Aus diesem Grund steht es Ihnen frei,
        personenbezogene Daten auch auf alternativen Wegen, beispielsweise telefonisch oder per Post, an uns zu
        übermitteln.
        <br>
        <br>
        <h1>2. Verantwortlicher</h1>
        Verantwortlicher im Sinne der DSGVO ist die:
        <br>
        <br>
        Uniwunder GmbH
        Bergstraße 29, 01069 Dresden, Deutschland
        <br>
        Telefon: +49 (0) 351 799 033 84
        E-Mail: <a href="mailto:info@uniwunder.com">info&#64;uniwunder.com</a>
        <br>
        <br>
        <h1>3. Datenschutzbeauftragter</h1>
        Kontakt zu unserem Datenschutzbeauftragten können Sie über dieses <a
            href="https://datenconsulting.de/anfragen-datenschutzbeauftragten/">FORMULAR</a> oder über die oben
        genannten Kontaktdaten z. Hd. d. Datenschutzbeauftragten aufnehmen. Betroffene Personen können sich jederzeit
        bei allen Fragen und Anregungen zum Datenschutz an uns oder unseren Datenschutzbeauftragten wenden.
        <br>
        <br>
        <h1>4. Rechtsgrundlage der Verarbeitung</h1>
        Art. 6 Abs. 1 lit. a DS-GVO (i.V.m. § 15 Abs. 3 TMG) dient unserem Unternehmen als Rechtsgrundlage für
        Verarbeitungsvorgänge, bei denen wir eine Einwilligung für einen bestimmten Verarbeitungszweck einholen.
        <br>
        <br>
        Ist die Verarbeitung personenbezogener Daten zur Erfüllung eines Vertrags, dessen Vertragspartei Sie sind,
        erforderlich, wie dies beispielsweise bei Verarbeitungsvorgängen der Fall ist, die für eine Lieferung von Waren
        oder die Erbringung einer sonstigen Leistung oder Gegenleistung notwendig sind, so beruht die Verarbeitung auf
        Art. 6 Abs. 1 lit. b DS-GVO. Gleiches gilt für solche Verarbeitungsvorgänge die zur Durchführung
        vorvertraglicher Maßnahmen erforderlich sind, etwa in Fällen von Anfragen zu unseren Produkten oder Leistungen.
        <br>
        <br>
        Unterliegt unser Unternehmen einer rechtlichen Verpflichtung durch welche eine Verarbeitung von
        personenbezogenen Daten erforderlich wird, wie beispielsweise zur Erfüllung steuerlicher Pflichten, so basiert
        die Verarbeitung auf Art. 6 Abs. 1 lit. c DS-GVO.
        <br>
        <br>
        In seltenen Fällen könnte die Verarbeitung von personenbezogenen Daten erforderlich werden, um lebenswichtige
        Interessen der betroffenen Person oder einer anderen natürlichen Person zu schützen. Dies wäre beispielsweise
        der Fall, wenn ein Besucher in unserem Betrieb verletzt werden würde und daraufhin sein Name, sein Alter, seine
        Krankenkassendaten oder sonstige lebenswichtige Informationen an einen Arzt, ein Krankenhaus oder sonstige
        Dritte weitergegeben werden müssten. Dann würde die Verarbeitung auf Art. 6 Abs. 1 lit. d DS-GVO beruhen.
        <br>
        <br>
        Letztlich könnten Verarbeitungsvorgänge auf Art. 6 Abs. 1 lit. f DS-GVO beruhen. Auf dieser Rechtsgrundlage
        basieren Verarbeitungsvorgänge, die von keiner der vorgenannten Rechtsgrundlagen erfasst werden, wenn die
        Verarbeitung zur Wahrung eines berechtigten Interesses unseres Unternehmens oder eines Dritten erforderlich ist,
        sofern die Interessen, Grundrechte und Grundfreiheiten des Betroffenen nicht überwiegen. Solche
        Verarbeitungsvorgänge sind uns insbesondere deshalb gestattet, weil sie durch den Europäischen Gesetzgeber
        besonders erwähnt wurden. Er vertrat insoweit die Auffassung, dass ein berechtigtes Interesse anzunehmen sein
        könnte, wenn Sie ein Kunde unseres Unternehmens sind (Erwägungsgrund 47 Satz 2 DS-GVO).
        <br>
        <br>
        <h1>5. Übermittlung von Daten an Dritte</h1>
        Eine Übermittlung Ihrer persönlichen Daten an Dritte zu anderen als den im Folgenden aufgeführten Zwecken findet
        nicht statt.
        <br>
        <br>
        Wir geben Ihre persönlichen Daten nur an Dritte weiter, wenn:
        <br>
        <ol>
            <li>Sie Ihre nach Art. 6 Abs. 1 S. 1 lit. a DS-GVO ausdrückliche Einwilligung dazu erteilt haben,</li>
            <li>die Weitergabe nach Art. 6 Abs. 1 S. 1 lit. f DS-GVO zur Wahrung unserer berechtigten Interessen
                zulässig ist und kein Grund zur Annahme besteht, dass Sie ein überwiegendes schutzwürdiges Interesse an
                der Nichtweitergabe Ihrer Daten haben,
            </li>
            <li>für den Fall, dass für die Weitergabe nach Art. 6 Abs. 1 S. 1 lit. c DS-GVO eine gesetzliche
                Verpflichtung besteht, sowie
            </li>
            <li>dies gesetzlich zulässig und nach Art. 6 Abs. 1 S. 1 lit. b DS-GVO für die Abwicklung von
                Vertragsverhältnissen mit Ihnen erforderlich ist.
            </li>
        </ol>
        <br>
        Um Ihre Daten zu schützen und uns gegebenenfalls eine Datenübermittlung in Drittländer (außerhalb der EU/des
        EWR)zu ermöglichen, haben wir Vereinbarungen zur Auftragsverarbeitung auf Grundlage der Standardvertragsklauseln
        der Europäischen Kommission abgeschlossen.
        <br>
        <br>
        <h1>6. Technik</h1>
        <h2>6.1 SSL/TLS-Verschlüsselung</h2>
        Diese Seite nutzt zur Gewährleistung der Sicherheit der Datenverarbeitung und zum Schutz der Übertragung
        vertraulicher Inhalte, wie zum Beispiel Bestellungen, Login-Daten oder Kontaktanfragen, die Sie an uns als
        Betreiber senden, eine SSL-bzw. TLS-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie daran, dass in
        der Adresszeile des Browsers statt einem "http://" ein "https://" steht und an dem Schloss-Symbol in Ihrer
        Browserzeile.
        <br>
        <br>
        Wir setzen diese Technologie ein, um Ihre übermittelten Daten zu schützen.
        <br>
        <br>
        <h2>6.2 Datenerfassung beim Besuch der Internetseite</h2>
        <br>
        Bei der lediglich informatorischen Nutzung unserer Website, also wenn Sie sich nicht registrieren oder uns
        anderweitig Informationen übermitteln, erheben wir nur solche Daten, die Ihr Browser an unseren Server
        übermittelt (in sog. "Server-Logfiles"). Unsere Internetseite erfasst mit jedem Aufruf einer Seite durch Sie
        oder ein automatisiertes System eine Reihe von allgemeinen Daten und Informationen. Diese allgemeinen Daten und
        Informationen werden in den Logfiles des Servers gespeichert. Erfasst werden können die
        <br>
        <ol>
            <li>verwendeten Browsertypen und Versionen,</li>
            <li>das vom zugreifenden System verwendete Betriebssystem,</li>
            <li>die Internetseite, von welcher ein zugreifendes System auf unsere Internetseite gelangt (sogenannte
                Referrer),
            </li>
            <li>die Unterwebseiten, welche über ein zugreifendes System auf unserer Internetseite angesteuert werden,
            </li>
            <li>das Datum und die Uhrzeit eines Zugriffs auf die Internetseite,</li>
            <li>eine gekürzte Internet-Protokoll-Adresse (anonymisierte IP-Adresse),</li>
            <li>der Internet-Service-Provider des zugreifenden Systems.</li>
        </ol>
        <br>
        Bei der Nutzung dieser allgemeinen Daten und Informationen ziehen wir keine Rückschlüsse auf Ihre Person. Diese
        Informationen werden vielmehr benötigt, um
        <br>
        <ol>
            <li>die Inhalte unserer Internetseite korrekt auszuliefern,</li>
            <li>die Inhalte unserer Internetseite sowie die Werbung für diese zu optimieren,</li>
            <li>die dauerhafte Funktionsfähigkeit unserer IT-Systeme und der Technik unserer Internetseite zu
                gewährleisten sowie
            </li>
            <li>um Strafverfolgungsbehörden im Falle eines Cyberangriffes die zur Strafverfolgung notwendigen
                Informationen bereitzustellen.
            </li>
        </ol>
        <br>
        Diese erhobenen Daten und Informationen werden durch uns daher einerseits statistisch und ferner mit dem Ziel
        ausgewertet, den Datenschutz und die Datensicherheit in unserem Unternehmen zu erhöhen, um letztlich ein
        optimales Schutzniveau für die von uns verarbeiteten personenbezogenen Daten sicherzustellen. Die anonymen Daten
        der Server-Logfiles werden getrennt von allen durch eine betroffene Person angegebenen personenbezogenen Daten
        gespeichert.
        <br>
        <br>
        Die Rechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs. 1 S. 1 lit. f DS-GVO. Unser berechtigtes Interesse
        folgt aus oben aufgelisteten Zwecken zur Datenerhebung.
        <br>
        <br>

        <h1>7. Cookies</h1>
        <h2>7.1 Allgemeines zu Cookies</h2>
        Wir setzen auf unserer Internetseite Cookies ein. Hierbei handelt es sich um kleine Dateien, die Ihr Browser
        automatisch erstellt und die auf Ihrem IT-System (Laptop, Tablet, Smartphone o.ä.) gespeichert werden, wenn Sie
        unsere Seite besuchen.
        <br>
        <br>

        In dem Cookie werden Informationen abgelegt, die sich jeweils im Zusammenhang mit dem spezifisch eingesetzten
        Endgerät ergeben. Dies bedeutet jedoch nicht, dass wir dadurch unmittelbar Kenntnis von Ihrer Identität
        erhalten.
        <br>
        <br>

        Der Einsatz von Cookies dient einerseits dazu, die Nutzung unseres Angebots für Sie angenehmer zu gestalten. So
        setzen wir sogenannte Session-Cookies ein, um zu erkennen, dass Sie einzelne Seiten unserer Website bereits
        besucht haben. Diese werden nach Verlassen unserer Seite automatisch gelöscht.
        <br>
        <br>

        Darüber hinaus setzen wir ebenfalls zur Optimierung der Benutzerfreundlichkeit temporäre Cookies ein, die für
        einen bestimmten festgelegten Zeitraum auf Ihrem Endgerät gespeichert werden. Besuchen Sie unsere Seite erneut,
        um unsere Dienste in Anspruch zu nehmen, wird automatisch erkannt, dass Sie bereits bei uns waren und welche
        Eingaben und Einstellungen Sie getätigt haben, um diese nicht noch einmal eingeben zu müssen.
        <br>
        <br>

        Zum anderen setzten wir Cookies ein, um die Nutzung unserer Website statistisch zu erfassen und zum Zwecke der
        Optimierung unseres Angebotes für Sie auszuwerten. Diese Cookies ermöglichen es uns, bei einem erneuten Besuch
        unserer Seite automatisch zu erkennen, dass Sie bereits bei uns waren. Diese Cookies werden nach einer jeweils
        definierten Zeit automatisch gelöscht.
        <br>
        <br>

        <h2>7.2 Rechtsgrundlage für die Verwendung von Cookies</h2>
        Die durch Cookies verarbeiteten Daten, die für die ordnungsmäßige Funktion der Webseite benötigt werden, sind
        damit zur Wahrung unserer berechtigten Interessen sowie der Dritter nach Art. 6 Abs. 1 S. 1 lit. f DS-GVO
        erforderlich.
        <br>
        <br>

        Für alle anderen Cookies gilt, dass Sie über unser Opt-in Cookie-Banner Ihre Einwilligung hierzu im Sinne von
        Art. 6 Abs. 1 lit. a DS-GVO abgegeben haben.
        <br>
        <br>

        <h1>8. Inhalte unserer Internetseite</h1>
        <h2>8.1 Registrierung als Benutzer</h2>
        Sie haben die Möglichkeit, sich auf unserer Internetseite unter Angabe von personenbezogenen Daten zu
        registrieren.
        <br>
        <br>

        Welche personenbezogenen Daten dabei an uns übermittelt werden, ergibt sich aus der jeweiligen Eingabemaske, die
        für die Registrierung verwendet wird. Die von Ihnen eingegebenen personenbezogenen Daten werden ausschließlich
        für die interne Verwendung bei uns und für eigene Zwecke erhoben und gespeichert. Wir können die Weitergabe an
        einen oder mehrere Auftragsverarbeiter, beispielsweise einen Paketdienstleister, veranlassen, der die
        personenbezogenen Daten ebenfalls ausschließlich für eine interne Verwendung, die uns zuzurechnen ist, nutzt.
        <br>
        <br>

        Durch eine Registrierung auf unserer Internetseite wird ferner die von Ihrem Internet-Service-Provider (ISP)
        vergebene IP-Adresse, das Datum sowie die Uhrzeit der Registrierung gespeichert. Die Speicherung dieser Daten
        erfolgt vor dem Hintergrund, dass nur so der Missbrauch unserer Dienste verhindert werden kann, und diese Daten
        im Bedarfsfall ermöglichen, begangene Straftaten aufzuklären. Insofern ist die Speicherung dieser Daten zu
        unserer Absicherung erforderlich. Eine Weitergabe dieser Daten an Dritte erfolgt grundsätzlich nicht, sofern
        keine gesetzliche Pflicht zur Weitergabe besteht oder die Weitergabe der Strafverfolgung dient.
        <br>
        <br>

        Ihre Registrierung unter freiwilliger Angabe personenbezogener Daten dient uns außerdem dazu, Ihnen Inhalte oder
        Leistungen anzubieten, die aufgrund der Natur der Sache nur registrierten Benutzern angeboten werden können.
        Registrierten Personen steht die Möglichkeit frei, die bei der Registrierung angegebenen personenbezogenen Daten
        jederzeit abzuändern oder vollständig aus unserem dem Datenbestand löschen zu lassen.
        <br>
        <br>

        Wir erteilen Ihnen jederzeit auf Anfrage Auskunft darüber, welche personenbezogenen Daten über Sie gespeichert
        sind. Ferner berichtigen oder löschen wir personenbezogene Daten auf Ihren Wunsch, soweit dem keine gesetzlichen
        Aufbewahrungspflichten entgegenstehen. Ein in dieser Datenschutzerklärung namentlich benannter
        Datenschutzbeauftragter und alle weiteren Mitarbeiter stehen der betroffenen Person in diesem Zusammenhang als
        Ansprechpartner zur Verfügung.
        <br>
        <br>

        Die Verarbeitung Ihrer Datenerfolgt im Interesse einer komfortablen und einfachen Nutzung unserer Internetseite.
        Dies stellt ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DS-GVO dar.
        <br>
        <br>

        <h1>9. Newsletterversand</h1>
        <h2>9.1 Newsletterversand an Bestandskunden</h2>
        Wenn Sie uns Ihre E-Mailadresse beim Kauf von Waren bzw. Dienstleistungen zur Verfügung gestellt haben, behalten
        wir uns vor, Ihnen regelmäßig Angebote zu ähnlichen Waren bzw. Dienstleistungen, wie den bereits gekauften, aus
        unserem Sortiment per E-Mail zuzusenden. Hierfür müssen wir gemäß § 7 Abs. 3 UWG keine gesonderte Einwilligung
        von Ihnen einholen. Die Datenverarbeitung erfolgt insoweit allein auf Basis unseres berechtigten Interesses an
        personalisierter Direktwerbung gemäß Art. 6 Abs. 1 lit. f DS-GVO. Haben Sie der Nutzung Ihrer E-Mailadresse zu
        diesem Zweck anfänglich widersprochen, findet ein Mailversand unsererseits nicht statt. Sie sind berechtigt, der
        Nutzung Ihrer E-Mailadresse zu dem vorbezeichneten Werbezweck jederzeit mit Wirkung für die Zukunft durch eine
        Mitteilung an den zu Beginn genannten Verantwortlichen zu widersprechen. Hierfür fallen für Sie lediglich
        Übermittlungskosten nach den Basistarifen an. Nach Eingang Ihres Widerspruchs wird die Nutzung Ihrer
        E-Mailadresse zu Werbezwecken unverzüglich eingestellt.
        <br>
        <br>

        <h1>10. Unsere Aktivitäten in sozialen Netzwerken</h1>
        Damit wir auch in sozialen Netzwerken mit Ihnen kommunizieren und über unsere Leistungen informieren können,
        sind wir dort mit eigenen Seiten vertreten. Wenn Sie eine unserer Social Media Seiten besuchen, sind wir
        hinsichtlich der dadurch ausgelösten Verarbeitungsvorgänge, welche personenbezogene Daten betreffen, mit dem
        Anbieter der jeweiligen Social-Media-Plattform gemeinsam verantwortlich im Sinne des Art. 26 DS-GVO.
        <br>
        <br>

        Wir sind dabei nicht der originäre Anbieter dieser Seiten, sondern nutzen diese lediglich im Rahmen der uns
        gebotenen Möglichkeiten der jeweiligen Anbieter.
        <br>
        Daher weisen wir vorsorglich darauf hin, dass Ihre Daten möglicherweise auch außerhalb der Europäischen Union
        bzw. des Europäischen Wirtschaftsraumes verarbeitet werden. Eine Nutzung kann daher Datenschutzrisiken für Sie
        haben, da die Wahrung Ihrer Rechte z.B. auf Auskunft, Löschung, Widerspruch, etc. erschwert sein kann und die
        Verarbeitung in den sozialen Netzwerken häufig direkt zu Werbezwecken oder zur Analyse des Nutzerverhaltens
        durch die Anbieter erfolgt, ohne dass dies durch uns beeinflusst werden kann. Werden durch den Anbieter
        Nutzungsprofile angelegt, werden dabei häufig Cookies eingesetzt bzw. das Nutzungsverhalten direkt Ihrem eigenen
        Mitgliedprofil der sozialen Netzwerke zugeordnet (sofern Sie hier eingeloggt sind).
        <br>
        <br>

        Die beschriebenen Verarbeitungsvorgänge personenbezogener Daten erfolgen gemäß Art. 6 Abs. 1 lit. f DS-GVO auf
        Basis unseres berechtigten Interesses und des berechtigten Interesses des jeweiligen Anbieters, um mit Ihnen in
        einer zeitgemäßen Art und Weise kommunizieren bzw. Sie über unsere Leistungen informieren zu können. Müssen Sie
        bei den jeweiligen Anbietern eine Einwilligung zur Datenverarbeitung als Nutzer abgeben, bezieht sich die
        Rechtsgrundlage auf Art. 6 Abs. 1 lit. a DS-GVO i.V.m. Art. 7 DS-GVO.
        <br>
        <br>

        Da wir keine Zugriffe auf die Datenbestände der Anbieter haben, weisen wir darauf hin, dass Sie Ihre Rechte
        (z.B. auf Auskunft, Berichtigung, Löschung, etc.) am besten direkt bei dem jeweiligen Anbieter gelten machen.
        Weiterführende Informationen zur Verarbeitung Ihrer Daten in den sozialen Netzwerken und der Möglichkeit von
        Ihrem Widerspruchs- bzw. Widerrufsrecht (sog. Opt-Out) gebrauch zu machen, haben wir nachfolgend bei dem
        jeweiligen von uns eingesetzten Anbieter sozialer Netzwerke aufgeführt:
        <br>
        <br>

        <h2>10.1 Facebook</h2>
        (Mit-) Verantwortlicher für die Datenverarbeitung in Europa:
        <br>
        Facebook Ireland Ltd., 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland
        <br>
        <br>

        Datenschutzerklärung (Datenrichtlinie):
        <br>
        <a href="https://www.facebook.com/about/privacy">https://www.facebook.com/about/privacy</a>
        <br>
        <br>

        Opt-Out und Werbeeinstellungen:
        <br>
        <a href="https://www.facebook.com/ads/preferences/?entry_product=ad_settings_screen">https://www.facebook.com/ads/preferences/?entry_product=ad_settings_screen</a>
        <br>
        <a href="https://de-de.facebook.com/about/privacy/">https://de-de.facebook.com/about/privacy/</a>
        <br>
        <br>

        <h2>10.2 Instagram</h2>
        (Mit-) Verantwortlicher für die Datenverarbeitung in Deutschland:
        <br>
        Facebook Ireland Ltd., 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland
        <br>
        <br>

        Datenschutzerklärung (Datenrichtlinie):
        <br>
        <a href="http://instagram.com/legal/privacy/">http://instagram.com/legal/privacy/</a>
        <br>
        <br>

        Opt-Out und Werbeeinstellungen:
        <br>
        <a href="https://www.instagram.com/accounts/privacy_and_security/">https://www.instagram.com/accounts/privacy_and_security/</a>
        <br>
        <br>

        <h2>10.3 LinkedIn</h2>
        (Mit-) Verantwortlicher für die Datenverarbeitung in Europa:
        <br>
        LinkedIn Ireland Unlimited Company Wilton Place, Dublin 2, Irland
        <br>
        <br>

        Datenschutzerklärung:
        <br>
        <a href="https://www.linkedin.com/legal/privacy-policy">https://www.linkedin.com/legal/privacy-policy</a>
        <br>
        <br>

        Opt-Out und Werbeeinstellungen:
        <br>
        <a href="https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out">https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out</a>
        <br>
        <br>

        <h2>10.4 YouTube</h2>
        (Mit-) Verantwortlicher für die Datenverarbeitung in Europa:
        <br>
        Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland
        <br>
        <br>

        Datenschutzerklärung:
        <br>
        <a href="https://policies.google.com/privacy">https://policies.google.com/privacy</a>
        <br>
        <br>

        Opt-Out und Werbeeinstellungen:
        <br>
        <a href="https://adssettings.google.com/authenticated">https://adssettings.google.com/authenticated</a>
        <br>
        <br>

        <h2>10.5 XING</h2>
        (Mit-) Verantwortlicher für die Datenverarbeitung in Deutschland:
        <br>
        XING AG, Dammtorstraße 29-32, 20354 Hamburg, Deutschland
        <br>
        <br>

        Datenschutzerklärung:
        <br>
        <a href="https://privacy.xing.com/de/datenschutzerklaerung">https://privacy.xing.com/de/datenschutzerklaerung</a>
        <br>
        <br>

        Auskunftsanfragen für XING Mitglieder:
        <br>
        <a href="https://www.xing.com/settings/privacy/data/disclosure">https://www.xing.com/settings/privacy/data/disclosure</a>
        <br>
        <br>

        <h1>11. Webanalyse</h1>
        <h2>11.1 Facebook Pixel (Custom Audience)</h2>
        Diese Website verwendet den "Facebook-Pixel" der Facebook Inc., 1 Hacker Way, Menlo Park, CA 94025, USA
        ("Facebook"). Im Falle der Erteilung einer ausdrücklichen Einwilligung kann hierdurch das Verhalten von Nutzern
        nachverfolgt werden, nachdem diese eine Facebook-Werbeanzeige gesehen oder angeklickt haben. Dieses Verfahren
        dient dazu, die Wirksamkeit der Facebook-Werbeanzeigen für statistische und Marktforschungszwecke auszuwerten
        und kann dazu beitragen, zukünftige Werbemaßnahmen zu optimieren.
        <br>
        <br>

        Die erhobenen Daten sind für uns anonym, bieten uns also keine Rückschlüsse auf die Identität der Nutzer.
        Allerdings werden die Daten von Facebook gespeichert und verarbeitet, sodass eine Verbindung zum jeweiligen
        Nutzerprofil möglich ist und Facebook die Daten für eigene Werbezwecke, entsprechend der Facebook-
        Datenverwendungsrichtlinie (<a href="https://www.facebook.com/about/privacy/">https://www.facebook.com/about/privacy/</a>)
        verwenden kann. Sie können Facebook sowie dessen Partnern das Schalten von Werbeanzeigen auf und außerhalb von
        Facebook ermöglichen. Es kann ferner zu diesen Zwecken ein Cookie auf Ihrem Rechner gespeichert werden. Diese
        Verarbeitungsvorgänge erfolgen ausschließlich bei Erteilung der ausdrücklichen Einwilligung gemäß Art. 6 Abs. 1
        lit. a DS-GVO.
        <br>
        <br>

        Diese Verarbeitungsvorgänge erfolgen ausschließlich bei Erteilung der ausdrücklichen Einwilligung gemäß Art. 6
        Abs. 1 lit. a DS-GVO.
        <br>
        <br>

        Um die Verwendung von Cookies auf Ihrem IT-System zu deaktivieren, können Sie Ihren Internetbrowser so
        einstellen, dass zukünftig keine Cookies mehr auf Ihrem IT-system abgelegt werden können bzw. bereits abgelegte
        Cookies gelöscht werden. Das Abschalten sämtlicher Cookies kann jedoch dazu führen, dass einige Funktionen auf
        unseren Internetseiten nicht mehr ausgeführt werden können. Sie können der Verwendung von Cookies durch
        Drittanbieter wie z.B. Facebook auch auf folgender Website der Digital Advertising Alliance deaktivieren: <a
            href="https://www.aboutads.info/choices/">https://www.aboutads.info/choices/</a>
        <br>
        <br>

        Darüber hinaus können Sie Cookies zur Reichweitenmessung und Werbezwecken über folgenden Webseiten deaktivieren:
        <br>
        <ol>
            <li><a href="http://optout.networkadvertising.org/">http://optout.networkadvertising.org/</a></li>
            <li><a href="http://www.youronlinechoices.com/uk/your-ad-choices/">http://www.youronlinechoices.com/uk/your-ad-choices/</a>
            </li>
        </ol>
        <br>
        Wir weisen Sie darauf hin, dass auch diese Einstellung gelöscht wird, wenn Sie Ihre Cookies löschen.
        <br>
        <br>

        <h2>11.2 Google Analytics und Optimize</h2>
        Auf unseren Webseiten nutzen wir Google Analytics, ein Webanalysedienst der Google Ireland Limited (<a
            href="https://www.google.de/intl/de/about/">https://www.google.de/intl/de/about/</a>) (Gordon House, Barrow
        Street, Dublin 4, Irland; im Folgenden "Google"). In diesem Zusammenhang werden pseudonymisierte Nutzungsprofile
        erstellt und Cookies (siehe Punkt "Cookies") verwendet. Die durch den Cookie erzeugten Informationen über Ihre
        Benutzung dieser Website wie
        <br>
        <ol>
            <li>Browser-Typ/-Version,</li>
            <li>verwendetes Betriebssystem,</li>
            <li>Referrer-URL (die zuvor besuchte Seite),</li>
            <li>Hostname des zugreifenden Rechners (IP-Adresse),</li>
            <li>Uhrzeit der Serveranfrage,</li>
        </ol>
        <br>
        werden an einen Server von Google in den USA übertragen und dort gespeichert. Die Informationen werden
        verwendet, um die Nutzung der Website auszuwerten, um Reports über die Websiteaktivitäten zusammenzustellen und
        um weitere mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen zu Zwecken der
        Marktforschung und bedarfsgerechten Gestaltung dieser Internetseiten zu erbringen. Auch werden diese
        Informationen gegebenenfalls an Dritte übertragen, sofern dies gesetzlich vorgeschrieben ist oder soweit Dritte
        diese Daten im Auftrag verarbeiten. Es wird in keinem Fall Ihre IP-Adresse mit anderen Daten von Google
        zusammengeführt. Die IP-Adressen werden anonymisiert, so dass eine Zuordnung nicht möglich ist (IP-Masking).
        <br>
        <br>
        Darüber hinaus verwenden wir die von Google Analytics erhobenen Daten im Rahmen des in Google Analytics
        intergierten Tools Google Optimize („Optimize“), um die Attraktivität, den Inhalt und die Funktionalität unserer
        Website zur erhöhen, indem wir neue Funktionen und Inhalte einem prozentualen Anteil unserer Nutzer ausspielen
        und die Nutzungsänderung statistisch auswerten. Anhand eines zusätzlichen Cookies (siehe Punkt „Cookies“)
        ermöglicht uns Optimize verschiedene Versionen einer Website sowie die Reaktionen der Nutzer auf diese
        verschiedenen Versionen zu testen. So können wir beispielsweise testen, an welcher Stelle innerhalb der Website
        eine bestimmte Information am attraktivsten ist. Das zusätzliche Cookie wird verwendet, um festzustellen, ob Sie
        an einem solchen Test teilnehmen. Gleichzeitig legt es das Ende des Tests fest.
        <br>
        <br>
        Sie können die Installation der Cookies durch eine entsprechende Einstellung der Browser-Software verhindern;
        wir weisen jedoch darauf hin, dass in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website
        vollumfänglich genutzt werden können.
        <br>
        <br>

        Diese Verarbeitungsvorgänge erfolgen ausschließlich bei Erteilung der ausdrücklichen Einwilligung gemäß Art. 6
        Abs. 1 lit. a DS-GVO.
        <br>
        <br>

        Sie können darüber hinaus die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der Website
        bezogenen Daten (inkl. Ihrer IP-Adresse) sowie die Verarbeitung dieser Daten durch Google verhindern, indem Sie
        ein Browser-Add-on herunterladen und installieren (<a href="https://tools.google.com/dlpage/gaoptout?hl=de">https://tools.google.com/dlpage/gaoptout?hl=de</a>).
        <br>
        <br>

        Alternativ zum Browser-Add-on, insbesondere bei Browsern auf mobilen Endgeräten, können Sie die Erfassung durch
        Google Analytics zudem verhindern, indem Sie auf den folgenden Link klicken: Google Analytics deaktivieren. Es
        wird ein Opt-out-Cookie gesetzt, das die zukünftige Erfassung Ihrer Daten beim Besuch dieser Website verhindert.
        Der Opt-out-Cookie gilt nur in diesem Browser und nur für unsere Website und wird auf Ihrem Gerät abgelegt.
        Löschen Sie die Cookies in diesem Browser, müssen Sie das Opt-out-Cookie erneut setzen.
        <br>
        <br>


        Weitere Informationen zum Datenschutz im Zusammenhang mit Google Analytics finden Sie etwa in der Google
        Analytics-Hilfe (<a href="https://support.google.com/analytics/answer/6004245?hl=de">https://support.google.com/analytics/answer/6004245?hl=de</a>).
        <br>
        <br>

        <h1>12. Plugins und andere Dienste</h1>
        <h2>12.1 Google Tag Manager</h2>
        Diese Webseite verwendet Google Tag Manager, eine cookielose Domain die keine personenbezogenen Daten erfasst.
        <br>
        <br>
        Durch dieses Tool können "Website-Tags" (d.h. Schlagwörter, welche in HTML Elemente eingebunden werden)
        implementiert und über eine Oberfläche verwaltet werden. Durch den Einsatz des Google Tag Manager können wir
        automatisiert nachvollziehen, welchen Button, Link oder welches personalisierte Bild Sie aktiv angeklickt haben
        und können sodann festhalten, welche Inhalte unserer Webseite für Sie besonders interessant sind.
        <br>
        <br>
        Das Tool sorgt zudem für die Auslösung anderer Tags, die ihrerseits unter Umständen Daten erfassen. Google Tag
        Manager greift nicht auf diese Daten zu. Wenn Sie auf Domain- oder Cookie-Ebene eine Deaktivierung vorgenommen
        haben, bleibt diese für alle Tracking-Tags bestehen, die mit Google Tag Manager implementiert werden.
        <br>
        <br>
        Diese Verarbeitungsvorgänge erfolgen ausschließlich bei Erteilung der ausdrücklichen Einwilligung gemäß Art. 6
        Abs. 1 lit. a DS-GVO.
        <br>
        <br>
        <h2>12.2 Messenger Dienst Twilio</h2>
        <br>
        Registrierte Kandidaten können in ihrem Profil auf unserer Plattform angeben, ob Sie Informationen von uns per
        Messenger-Dienst (i.d.R. WhatsApp oder SMS) erhalten wollen. Wenn dies erfolgt, nutzen wir dafür den
        Dienstleister Twilio Germany GmbH, Rosenheimer Str. 143C, 81671 München (twilio.com). Dieser Dienstleister
        erhält von uns die Telefonnummer des Kandidaten für das Einspielen unserer Nachrichten in den Messenger. Diese
        Nachrichten bestehen i.d.R. aus Erinnerungen für Jobvorschläge, Stellenangebote, interessante Arbeitgeber u.ä.
        Wir senden ausschließlich auf unsere Leistungen bezogene Informationen über den Dienst.
        <br>
        <br>
        Twilio agiert als Auftragsverarbeiter. Wir haben einen Auftragsverarbeitungsvertrag (DPA) geschlossen (vgl. <a
            href="https://www.twilio.com/legal/data-protection-addendum">https://www.twilio.com/legal/data-protection-addendum</a>),
        der uns Weisungs- und Kontrollrechte sichert. Sollte ein Datentransfer aus der EU bzw. dem EWR hinaus erfolgen,
        was grds. in die USA möglich ist, findet dieser statt auf Basis der von der Datenschutzbehörde genehmigten
        verbindlichen internen Datenschutzvorschriften von Twilio (Binding Corporate Rules, BCR, hier abrufbar: <a
            href="https://www.twilio.com/legal/binding-corporate-rules">https://www.twilio.com/legal/binding-corporate-rules</a>).
        Der Transfer ist somit nach Art. 46 Absatz 2 b) i.V.m. Art. 47 DSGVO zulässig.
        <br>
        <br>
        <h1>13. Werbung</h1>
        <h2>13.1 Google Ads (vormals AdWords)</h2>
        Unsere Website nutzt die Funktionen von Google Ads, hiermit werben wir für diese Website in den
        Google-Suchergebnissen, sowie auf Dritt-Websites. Anbieter ist die Google Ireland Limited, Gordon House, Barrow
        Street, Dublin 4, Irland ("Google"). Zu diesem Zweck setzt Google ein Cookie im Browser Ihres Endgeräts, welches
        automatisch mittels einer pseudonymen Cookie-ID und auf Grundlage der von Ihnen besuchten Seiten eine
        interessensbasierte Werbung ermöglicht.
        <br>
        <br>
        Diese Verarbeitungsvorgänge erfolgen ausschließlich bei Erteilung der ausdrücklichen Einwilligung gemäß Art. 6
        Abs. 1 lit. a DS-GVO.
        <br>
        <br>
        Eine darüberhinausgehende Datenverarbeitung findet nur statt, sofern Sie gegenüber Google zugestimmt haben, dass
        Ihr Internet- und App-Browserverlauf von Google mit Ihrem Google-Konto verknüpft wird und Informationen aus
        Ihrem Google-Konto zum Personalisieren von Anzeigen verwendet werden, die Sie im Web betrachten. Sind Sie in
        diesem Fall während des Seitenbesuchs unserer Webseite bei Google eingeloggt, verwendet Google Ihre Daten
        zusammen mit Google Analytics-Daten, um Zielgruppenlisten für geräteübergreifendes Remarketing zu erstellen und
        zu definieren. Dazu werden Ihre personenbezogenen Daten von Google vorübergehend mit Google Analytics-Daten
        verknüpft, um Zielgruppen zu bilden.
        <br>
        <br>
        Sie können die Setzung von Cookies für Anzeigenvorgaben dauerhaft deaktivieren, indem Sie das unter folgendem
        Link verfügbare Browser-Plug-in herunterladen und installieren:
        <br>
        <br>
        Alternativ können Sie sich bei der Digital Advertising Alliance unter der Internetadresse youradchoices.com über
        das Setzen von Cookies informieren und Einstellungen hierzu vornehmen. Schließlich können Sie Ihren Browser so
        einstellen, dass Sie über das Setzen von Cookies informiert werden und einzeln über deren Annahme entscheiden
        oder die Annahme von Cookies für bestimmte Fälle oder generell ausschließen. Bei der Nichtannahme von Cookies
        kann die Funktionalität unserer Website eingeschränkt sein.
        <br>
        <br>
        Google Ireland Limited mit Sitz in Irland ist für das us-europäische Datenschutzübereinkommen "Privacy Shield"
        zertifiziert, welches die Einhaltung des in der EU geltenden Datenschutzniveaus gewährleistet.
        <br>
        <br>
        Weitergehende Informationen und die Datenschutzbestimmungen bezüglich Werbung und Google können Sie hier
        einsehen: https://www.google.com/policies/technologies/ads/
        <br>
        <br>
        <h2>13.2 Google AdSense</h2>
        Wir haben auf dieser Internetseite Google AdSense integriert. Google AdSense ist ein Online-Dienst, über welchen
        eine Vermittlung von Werbung auf Drittseiten ermöglicht wird. Google AdSense beruht auf einem Algorithmus,
        welcher die auf Drittseiten angezeigten Werbeanzeigen passend zu den Inhalten der jeweiligen Drittseite
        auswählt. Google AdSense gestattet ein interessenbezogenes Targeting des Internetnutzers, welches mittels
        Generierung von individuellen Benutzerprofilen umgesetzt wird.
        <br>
        <br>
        Betreibergesellschaft der Google-AdSense-Komponente ist die Google Ireland Limited, Gordon House, Barrow Street,
        Dublin 4, Irland.
        <br>
        <br>
        Der Zweck der Google-AdSense-Komponente ist die Einbindung von Werbeanzeigen auf unserer Internetseite.
        Google-AdSense setzt ein Cookie auf Ihrem IT-System. Mit der Setzung des Cookies wird der Alphabet Inc., 1600
        Amphitheatre Pkwy, Mountain View, CA 94043-1351, USA, eine Analyse der Benutzung unserer Internetseite
        ermöglicht. Durch jeden Aufruf einer der Einzelseiten dieser Internetseite, die durch uns betrieben wird und auf
        welcher eine Google-AdSense-Komponente integriert wurde, wird der Internetbrowser auf Ihrem IT-System
        automatisch durch die jeweilige Google-AdSense-Komponente veranlasst, Daten zum Zwecke der Online-Werbung und
        der Abrechnung von Provisionen an die Alphabet Inc. zu übermitteln. Im Rahmen dieses technischen Verfahrens
        erhält die Alphabet Inc. Kenntnis über personenbezogene Daten, wie Ihrer IP-Adresse, die der Alphabet Inc. unter
        anderem dazu dienen, die Herkunft der Besucher und Klicks nachzuvollziehen und in der Folge
        Provisionsabrechnungen zu ermöglichen.
        <br>
        <br>
        Sie können die Setzung von Cookies durch unsere Internetseite jederzeit mittels einer entsprechenden Einstellung
        des genutzten Internetbrowsers verhindern und damit der Setzung von Cookies dauerhaft widersprechen. Eine solche
        Einstellung des genutzten Internetbrowsers würde auch verhindern, dass die Alphabet Inc. ein Cookie auf Ihrem
        IT-System setzt. Zudem kann ein von der Alphabet Inc. bereits gesetzter Cookie jederzeit über den
        Internetbrowser oder andere Softwareprogramme gelöscht werden.
        <br>
        <br>
        Google AdSense verwendet zudem sogenannte Zählpixel. Ein Zählpixel ist eine Miniaturgrafik, die in
        Internetseiten eingebettet wird, um eine Logdatei-Aufzeichnung und eine Logdatei-Analyse zu ermöglichen, wodurch
        eine statistische Auswertung durchgeführt werden kann. Anhand des eingebetteten Zählpixels kann die Alphabet
        Inc. erkennen, ob und wann eine Internetseite von Ihrem IT-System geöffnet wurde und welche Links von Ihnen
        angeklickt wurden. Zählpixel dienen unter anderem dazu, den Besucherfluss einer Internetseite auszuwerten.
        <br>
        <br>
        Über Google AdSense werden personenbezogene Daten und Informationen, was auch die IP-Adresse umfasst und zur
        Erfassung und Abrechnung der angezeigten Werbeanzeigen notwendig ist, an die Alphabet Inc. in die Vereinigten
        Staaten von Amerika übertragen. Diese personenbezogenen Daten werden in den Vereinigten Staaten von Amerika
        gespeichert und verarbeitet. Die Alphabet Inc. gibt diese über das technische Verfahren erhobenen
        personenbezogenen Daten unter Umständen an Dritte weiter.
        <br>
        <br>
        Google-AdSense wird unter diesem Link https://www.google.de/intl/de/adsense/start/ genauer erläutert.
        <br>
        <br>
        Diese Verarbeitungsvorgänge erfolgen ausschließlich bei Erteilung der ausdrücklichen Einwilligung gemäß Art. 6
        Abs. 1 lit. a DS-GVO.
        <br>
        <br>
        <h2>13.3 Google Ads mit Conversion-Tracking</h2>
        Wir haben auf dieser Internetseite Google Ads integriert. Google Ads ist ein Dienst zur Internetwerbung, der es
        Werbetreibenden gestattet, sowohl Anzeigen in den Suchmaschinenergebnissen von Google als auch im
        Google-Werbenetzwerk zu schalten. Google Ads ermöglicht es einem Werbetreibenden, vorab bestimmte
        Schlüsselwörter festzulegen, mittels derer eine Anzeige in den Suchmaschinenergebnissen von Google
        ausschließlich dann angezeigt wird, wenn der Nutzer mit der Suchmaschine ein schlüsselwortrelevantes
        Suchergebnis abruft. Im Google-Werbenetzwerk werden die Anzeigen mittels eines automatischen Algorithmus und
        unter Beachtung der zuvor festgelegten Schlüsselwörter auf themenrelevanten Internetseiten verteilt.
        <br>
        <br>
        Betreibergesellschaft der Dienste von Google Ads ist die Google Ireland Limited, Gordon House, Barrow Street,
        Dublin 4, Irland.
        <br>
        <br>
        Der Zweck von Google Ads ist die Bewerbung unserer Internetseite durch die Einblendung von interessenrelevanter
        Werbung auf den Internetseiten von Drittunternehmen und in den Suchmaschinenergebnissen der Suchmaschine Google
        und eine Einblendung von Fremdwerbung auf unserer Internetseite.
        <br>
        <br>
        Gelangen Sie über eine Google-Anzeige auf unsere Internetseite, wird auf Ihrem IT-System durch Google ein
        sogenannter Conversion-Cookie abgelegt. Ein Conversion-Cookie verliert nach dreißig Tagen seine Gültigkeit und
        dient nicht zu Ihrer Identifikation. Über den Conversion-Cookie wird, sofern das Cookie noch nicht abgelaufen
        ist, nachvollzogen, ob bestimmte Unterseiten, beispielsweise der Warenkorb von einem Online-Shop-System, auf
        unserer Internetseite aufgerufen wurden. Durch den Conversion-Cookie können sowohl wir als auch Google
        nachvollziehen, ob ein Nutzer, der über eine AdWords-Anzeige auf unsere Internetseite gelangt ist, einen Umsatz
        generierte, also einen Warenkauf vollzogen oder abgebrochen hat.
        <br>
        <br>
        Die durch die Nutzung des Conversion-Cookies erhobenen Daten und Informationen werden von Google verwendet, um
        Besuchsstatistiken für unsere Internetseite zu erstellen. Diese Besuchsstatistiken werden durch uns wiederum
        genutzt, um die Gesamtanzahl der Nutzer zu ermitteln, welche über Ads-Anzeigen an uns vermittelt wurden, also um
        den Erfolg oder Misserfolg der jeweiligen Ads-Anzeige zu ermitteln und um unsere Ads-Anzeigen für die Zukunft zu
        optimieren. Weder unser Unternehmen noch andere Werbekunden von Google-Ads erhalten Informationen von Google,
        mittels derer Sie identifiziert werden könnten.
        <br>
        <br>
        Mittels des Conversion-Cookies werden personenbezogene Informationen, beispielsweise die durch Sie besuchten
        Internetseiten, gespeichert. Bei jedem Besuch unserer Internetseiten werden demnach personenbezogene Daten,
        einschließlich der IP-Adresse des von Ihnen genutzten Internetanschlusses, an Google in den Vereinigten Staaten
        von Amerika übertragen. Diese personenbezogenen Daten werden durch Google in den Vereinigten Staaten von Amerika
        gespeichert. Google gibt diese über das technische Verfahren erhobenen personenbezogenen Daten unter Umständen
        an Dritte weiter.
        <br>
        <br>
        Sie können die Setzung von Cookies durch unsere Internetseite jederzeit mittels einer entsprechenden Einstellung
        des genutzten Internetbrowsers verhindern und damit der Setzung von Cookies dauerhaft widersprechen. Eine solche
        Einstellung des genutzten Internetbrowsers würde auch verhindern, dass Google einen Conversion-Cookie auf Ihrem
        IT-System setzt. Zudem kann ein von Google Ads bereits gesetzter Cookie jederzeit über den Internetbrowser oder
        andere Softwareprogramme gelöscht werden.
        <br>
        <br>
        Ferner besteht für Sie die Möglichkeit, der interessenbezogenen Werbung durch Google zu widersprechen. Hierzu
        müssen Sie von Ihrem genutzten Internetbrowser aus den Link www.google.de/settings/ads aufrufen und dort die
        gewünschten Einstellungen vornehmen.
        <br>
        <br>
        Diese Verarbeitungsvorgänge erfolgen ausschließlich bei Erteilung der ausdrücklichen Einwilligung gemäß Art. 6
        Abs. 1 lit. a DS-GVO.
        <br>
        <br>
        Weitere Informationen und die geltenden Datenschutzbestimmungen von Google können unter
        https://www.google.de/intl/de/policies/privacy/ abgerufen werden.
        <br>
        <br>
        <h1>14. Datenerhebung bei Registrierung für Talentagent</h1>
        <h2>14.1 Datenverarbeitung zur Aufnahme in Talentagent für Kandidaten</h2>
        Die von den Nutzern im Rahmen der Registrierung auf <a href="https://app.talentagent.de/">https://app.talentagent.de/</a>
        freiwillig in ihrem Profil gespeicherten personenbezogenen Daten werden von uns als Vermittler zum Zweck der
        Organisation und Weiterempfehlung bzw. Weitervermittlung an solche Arbeitgeber, welche eine zu Ihrem Profil
        passende Stelle ausgeschrieben und/oder die Sie durch Ihre Freigabe-Funktion freigeschaltet haben, verarbeitet.
        Eine Verarbeitung stellt hierbei im Grunde jede Art und Weise des Umgangs mit diesen Daten dar.
        <br>
        <br>
        Mit Ihrer Registrierung schließen Sie mit uns einen für Sie kostenfreien Vertrag über unsere Leistungen, welche
        insbesondere in der Weiterempfehlung Ihrer personenbezogenen Daten an potenzielle Arbeitgeber und an mit uns
        kooperierende Personaldienstleister liegen, ab. Durch die Weiterempfehlung erhält der Arbeitgeber oder
        Personaldienstleister Zugriff auf Ihre freiwillig bereitgestellten und für die Stelle relevanten Daten. Dies
        geschieht durch Weitergabe eines Kurzprofils („Dossiers“) in geeigneter und geschützter Form (telefonisch,
        schriftlich, elektronisch) oder durch passwortgeschützten Zugriff auf Ihr Nutzerprofil im Talentagent. Eine
        Weitergabe Ihrer Daten an Arbeitgeber oder Personaldienstleister, welche aus unserer Sicht keine Ihren
        Qualifikationen entsprechende Stellenausschreibungen veröffentlicht haben, findet dabei nicht statt.
        <br>
        <br>
        Rechtsgrundlage für die Verarbeitung und die Weiterleitung an potentielle Arbeitgeber oder mit uns kooperierende
        Personaldienstleister ist Ihre durch die Freigabe-Funktion erteilte Einwilligung gemäß Art. 6 Abs. 1 S. 1 a)
        DS-GVO und/oder die Durchführung vorvertraglicher Maßnahmen auf Anfrage des Betroffenen bzw. die Erfüllung eines
        mit Ihnen bei der Registrierung geschlossenen Vertrages nach Art. 6 Abs. 1 S. 1 b) DS-GVO. Die Verarbeitung und
        die Weitergabe sind zudem erforderlich zur Erbringung unserer Leistungen.
        <br>
        <br>
        Sie können Ihre erteilte Einwilligung jederzeit widerrufen bzw. der Verarbeitung jederzeit widersprechen. Bitte
        beachten Sie dabei, dass der Widerruf erst für die Zukunft wirkt. Verarbeitungen, die vor dem Widerruf erfolgt
        sind, sind daher vom Widerruf nicht betroffen. Der Widerruf bzw. Widerspruch kann an die oben genannten
        Kontaktdaten oder an unseren Datenschutzbeauftragten (siehe Punkt 2) erfolgen.
        <br>
        <br>
        Wir verarbeiten Ihre Daten für die Dauer des Bestehens Ihres Kontos bei <a href="https://app.talentagent.de/">https://app.talentagent.de/</a>.
        Sie können Ihr Konto jedoch jederzeit kündigen oder auch sperren. Wir werden Ihre Daten dann löschen, sofern wir
        nicht gesetzlich zur weiteren Speicherung oder Aufbewahrung verpflichtet sind.
        <br>
        <br>
        Weiterhin möchten wir Sie darauf hinweisen, dass die Arbeitgeber und Personaldienstleister mit
        Zugänglichmachung/Übermittlung der Daten weitere Verantwortliche im Sinne des Datenschutzrechts werden. Das
        bedeutet, dass Sie Ihre zuvor genannten Betroffenenrechte auch dem Arbeitgeber und dem Personaldienstleister
        gegenüber geltend machen können.
        <br>
        <br>

        <h2>14.2 Datenverarbeitung zur Aufnahme in Talentagent für Ansprechpartner interessierter Firmen</h2>
        Die von den Nutzern im Rahmen der Registrierung auf <a href="https://arbeitgeber.talentagent.de/">https://arbeitgeber.talentagent.de/</a>
        freiwillig in ihrem Profil gespeicherten personenbezogenen Daten werden von uns zum Zweck der Kontaktaufnahme
        bezüglich unseres Leistungsangebots und im Weiteren zur Erfüllung unserer Leistung, beispielsweise zur Absprache
        über potentielle Kandidaten, verwendet.
        <br>
        <br>
        Ihre persönlichen Daten werden dabei nicht an Dritte weitergegeben.
        <br>
        <br>
        Sie können Ihre erteilte Einwilligung jederzeit widerrufen bzw. der Verarbeitung jederzeit widersprechen. Bitte
        beachten Sie dabei darauf, dass der Widerruf erst für die Zukunft wirkt. Verarbeitungen, die vor dem Widerruf
        erfolgt sind, sind daher vom Widerruf nicht betroffen. Der Widerruf bzw. Widerspruch kann an die oben genannten
        Kontaktdaten oder an unseren Datenschutzbeauftragten (siehe Punkt 2) erfolgen.
        <br>
        <br>
        Wir verarbeiten Ihre Daten für die Dauer des Bestehens Ihres Kontos bei <a
            href="https://arbeitgeber.talentagent.de/">https://arbeitgeber.talentagent.de/</a>. Sie können Ihr Konto
        jedoch jederzeit kündigen oder auch sperren. Wir werden Ihre Daten dann löschen, sofern wir nicht gesetzlich zur
        weiteren Speicherung oder Aufbewahrung verpflichtet sind.
        <br>
        <br>
        <h1>15. Datenerhebung bei Inanspruchnahme eines Karrierecoach</h1>
        Wir bieten Ihnen die Möglichkeit, Ihre Job-Chancen zu erhöhen und sich von einem Karrierecoach unterstützen zu
        lassen.
        <br>
        <br>
        Karrierecoaches sind freie Handelsvertreter unseres Partners MLP Finanzberatung SE, Alte Heerstraße 40, 69168
        Wiesloch (nachfolgend „MLP“). Zur Kontaktaufnahme und Durchführung der angeforderten Leistung übermitteln wir
        Ihre Kontaktdaten (Vorname, Nachname, E-Mail-Adresse und Telefonnummer) auf Grundlage von Art. 6 Abs. 1 b) DSGVO
        an Ihren Coach.
        Bitte beachten Sie, dass nach Übertragung der Daten an einen Karrierecoach die Weiterverarbeitung durch diesen
        als verantwortliche Stelle erfolgt. Über Art und Umfang der Datenverarbeitung werden Sie vom Coach noch einmal
        gesondert informiert. <b>Der Karrierecoach kann Sie via Telefon, SMS, WhatsApp, E-Mail und Post zu den folgenden
        Belangen kontaktieren:
        <br>
        <br>
        <ul>
        <li>Bestätigung der Anmeldung</li>
        <li>ggf. Übermittlung von Begleitmaterialien</li>
        <li>Terminierung der gewünschten Leistung</li>
        <li>Erinnerung an Ihren Termin</li>
        <li>Angebot eines Ersatztermins, wenn Sie trotz vereinbartem Termin nicht erscheinen konnten</li>
        <li>Nachfrage, ob bei Ihnen weiterhin ein Interesse am Termin besteht, sofern Sie mehr als zwei Wochen nach Ihrer</li>
        <li>Anmeldung keinen Termin erhalten haben</li>
        </ul>
        </b>
        <br>
        Diese Übermittlung erfolgt um die folgenden Leistungen zu erbringen:
        <br>
        <br>
        <ul>
            <li>Lebenslaufcheck (Durchsicht des Lebenslaufs, Tipps zur Veränderung)</li>
            <li>Gehaltsverhandlungs-Workshop (Kann beim Coach oder bei MLP mitgemacht werden)</li>
            <li>Potenzialanalyse (Wird entweder gemeinsam mit dem Coach gemacht oder einfach ein Gutschein
                bereitgestellt)
            </li>
            <li>Finanzberatung zum Jobeinstieg (Vermögensplanung, Versicherungen..)</li>
            <li>Unterstützung bei der Nutzung der Plattform, bspw. Beratung über die Eignung von bestimmten Stellen für
                Ihr Profil.
            </li>
        </ul>
        <br>
        Mit ihrer Einwilligung erlauben Sie darüber hinaus, dass ihr Coach zur Ausführung der o.g. Leistungen Zugriff
        auf jegliche Daten und Unterlagen, die Sie auf talentagent.de eingeben, erhält. Diese Einwilligung können Sie
        jederzeit widerrufen, indem Sie uns eine Email an <a href="mailto:info@talentagent.de">info&#64;talentagent.de</a> senden. Bei einem Widerruf wird der
        beschriebene Zugriff für den Coach gesperrt.
        <br>
        <br>
        Darüber hinaus haben Sie die Rechte nach Punkt 17 dieser Erklärung.
        <br>
        <br>
        <h1>16. Datenweitergabe an Personalvermittler</h1>
        Um Sie bei zukünftigen Stellenangeboten zu berücksichtigen geben wir Ihre Daten an Personalvermittler weiter,
        wenn Sie uns hierzu Ihre Einwilligung erteilen.
        <br>
        <br>

        Sie haben das Recht, der Ihre Einwilligung zur Datenweitergabe jederzeit zu widerrufen.
        <br>
        Darüber hinaus haben Sie die Rechte nach Punkt 17 dieser Erklärung.
        <br>
        <br>

        Bitte beachten Sie, dass nach Übertragung der Daten an einen Personalvermittler die Weiterverarbeitung durch
        diesen als verantwortliche Stelle erfolgt. Über Art und Umfang der Datenverarbeitung werden Sie vom
        Personalvermittler noch einmal gesondert informiert.
        <br>
        <br>

        <h1>17. Datenweitergabe bei Anmeldung zum Karriereevent</h1>

        Da der Zweck unseres Services darin besteht, Bewerber und Unternehmen im Rahmen von Karriere-Veranstaltungen
        miteinander zu verknüpfen, werden wir den betreffenden (und benannten) Veranstaltern sowie ggf. den (benannten)
        Unternehmen, die an einer Veranstaltung teilnehmen, für die Sie sich beworben und/oder angemeldet haben, Zugang
        zu den nachfolgenden, übermittelten personenbezogenen Daten gewähren und diese weitergeben:
        <br>
        <br>
        <ul>
            <li>Vorname, Nachname</li>
            <li>E-Mail-Adresse</li>
            <li>Telefonnummer</li>
            <li>ggf. Lebenslauf</li>
        </ul>

        Dieser Zugang erlaubt es den Unternehmen, Informationen über Sie einzuholen, um zu beurteilen, ob sie vor,
        während oder nach der Veranstaltung mit Ihnen in Kontakt treten möchten. Zudem werden sie von den Veranstaltern
        zum Zwecke der Vorbereitung, Durchführung und Nachbereitung der Veranstaltung genutzt. Die Veranstalter und
        Unternehmen sind berechtigt, Ihre Daten für die Dauer von einem Jahr herunterzuladen und zu speichern. Jedoch
        ist es den Veranstaltern und Unternehmen untersagt, Ihre Daten an Dritte weiterzuleiten, für Marketingzwecke zu
        nutzen oder sie in sonstiger Weise öffentlich zugänglich zu machen. Die Datenverarbeitung (inklusive der
        Datenweitergabe) durch uns erfolgt auf Grundlage eines mit Ihnen im Rahmen der Anmeldung zum Event geschlossenen
        Vertrages. Rechtsgrundlage ist demzufolge Art. 6 Abs. 1 S. 1 lit. b DS-GVO.
        <br>
        Sie haben die Möglichkeit sich bis zu 24 Stunden vor Beginn der Veranstaltung von den Karriere-Veranstaltungen
        abzumelden. Nach Abmeldung werden Ihre Daten unverzüglich unzugänglich für die Veranstalter sowie die
        Unternehmen.

        <br>
        <br>
        Unsere Plattform, die Hochschulinitiative-Deutschland.de, bietet eine breite Palette von Events an, die von
        verschiedenen Veranstaltern organisiert werden.
        <br>
        Die einzelnen Veranstalter für die auf unserer Plattform angebotenen Events sind in unseren
        Datenschutzrichtlinien unter <a href="https://hochschulinitiative-deutschland.de/datenschutz">https://hochschulinitiative-deutschland.de/datenschutz</a>
        aufgeführt.
        <br>
        <br>


        <h1>18. Datenverarbeitung im Rahmen von Webinaren für Unternehmen (B2B) </h1>

        Die Verarbeitung Ihrer Daten dient zur Organisation und Durchführung des Webinars.
        <br>
        <br>
        Die Rechtsgrundlage dafür ist Art. 6 Abs. 1 S. 1 lit. b DS-GVO. Die Verarbeitung ist für die Teilnahme, sohin
        für die Erfüllung eines Vertrags, dessen Vertragspartei Sie sind, bzw. für die Durchführung vorvertraglicher
        Maßnahmen, die auf Ihre Anfrage hin erfolgen, erforderlich (Zur-Verfügung-Stellen des
        Webinars/Online-Vortrages). Weiterhin ist sie notwendig, um das Webinar bzw. den Online-Vortrag anbieten zu
        können.
        <br>
        <br>
        Sie haben ggf. die Möglichkeit, in einem Webinar die Chat-, Fragen- oder Umfragenfunktionen zu nutzen. Insoweit
        werden die von Ihnen gemachten Texteingaben verarbeitet, um diese im Webinar anzuzeigen und ggf. zu
        protokollieren.
        <br>
        <br>
        Uniwunder kann Sie in Bezug zum Webinar via Telefon, SMS, E-Mail und Post zu folgenden Belangen kontaktieren:

        <ul>
            <li>Bestätigung der Anmeldung & Teilnahme,</li>
            <li>Ggf. Übermittlung von Begleitmaterialien,</li>
            <li>Erinnerung an Ihren Webinartermin,</li>
            <li>Angebot eines Ersatzangebots, wenn Sie trotz Anmeldung nicht erscheinen konnten.</li>
        </ul>

        Die Daten der Anmelder werden zweckgebunden für die Verwaltung und Organisation eines Webinars verarbeitet und
        gelöscht, sobald der Zweck für die Verarbeitung weggefallen ist.
        <br>
        <br>
        Geben uns die Anmelder eine Einwilligung zur Weiterverwendung von Daten für Marketing oder z.B. zum Bezug von
        E-Mail-Newslettern, so erfolgt in diesem Fall mit Widerruf der Einwilligung die Datenlöschung.
        <br>
        <br>

        <h1>19. Ihre Rechte als betroffene Person</h1>
        <h2>19.1 Recht auf Bestätigung</h2>
        Sie haben das Recht, von uns eine Bestätigung darüber zu verlangen, ob Sie betreffende personenbezogene Daten
        verarbeitet werden.
        <br>
        <br>

        <h2>19.2 Recht auf Auskunft Art. 15 DS-GVO</h2>
        Sie haben das Recht, jederzeit von uns unentgeltliche Auskunft über die zu Ihrer Person gespeicherten
        personenbezogenen Daten sowie eine Kopie dieser Daten nach Maßgabe der gesetzlichen Bestimmungen zu erhalten.
        <br>
        <br>

        <h2>19.3 Recht auf Berichtigung Art. 16 DS-GVO</h2>
        Sie haben das Recht, die Berichtigung Sie betreffender unrichtiger personenbezogener Daten zu verlangen. Ferner
        steht Ihnen das Recht zu, unter Berücksichtigung der Zwecke der Verarbeitung, die Vervollständigung
        unvollständiger personenbezogener Daten zu verlangen.
        <br>
        <br>

        <h2>19.4 Löschung Art. 17 DS-GVO</h2>
        Sie haben das Recht, von uns zu verlangen, dass die Sie betreffenden personenbezogenen Daten unverzüglich
        gelöscht werden, sofern einer der gesetzlich vorgesehenen Gründe zutrifft und soweit die Verarbeitung bzw.
        Speicherung nicht erforderlich ist.
        <br>
        <br>

        <h2>19.5 Einschränkung der Verarbeitung Art. 18 DS-GVO</h2>
        Sie haben das Recht, von uns die Einschränkung der Verarbeitung zu verlangen, wenn eine der gesetzlichen
        Voraussetzungen gegeben ist.
        <br>
        <br>

        <h2>19.6 Datenübertragbarkeit Art. 20 DS-GVO</h2>
        Sie haben das Recht, die Sie betreffenden personenbezogenen Daten, welche uns durch Sie bereitgestellt wurden,
        in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten. Sie haben außerdem das Recht, diese
        Daten einem anderen Verantwortlichen ohne Behinderung durch uns, dem die personenbezogenen Daten bereitgestellt
        wurden, zu übermitteln, sofern die Verarbeitung auf der Einwilligung gemäß Art. 6 Abs. 1 lit. a DS-GVO oder Art.
        9 Abs. 2 lit. a DS-GVO oder auf einem Vertrag gemäß Art. 6 Abs. 1 lit. b DS-GVO beruht und die Verarbeitung
        mithilfe automatisierter Verfahren erfolgt, sofern die Verarbeitung nicht für die Wahrnehmung einer Aufgabe
        erforderlich ist, die im öffentlichen Interesse liegt oder in Ausübung öffentlicher Gewalt erfolgt, welche uns
        übertragen wurde.
        <br>
        <br>
        Ferner haben Sie bei der Ausübung Ihres Rechts auf Datenübertragbarkeit gemäß Art. 20 Abs. 1 DS-GVO das Recht,
        zu erwirken, dass die personenbezogenen Daten direkt von einem Verantwortlichen an einen anderen
        Verantwortlichen übermittelt werden, soweit dies technisch machbar ist und sofern hiervon nicht die Rechte und
        Freiheiten anderer Personen beeinträchtigt werden.
        <br>
        <br>
        <h2>19.7 Widerspruch Art. 21 DS-GVO</h2>
        Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit gegen die
        Verarbeitung Sie betreffender personenbezogener Daten, die aufgrund von Art. 6 Abs. 1 lit. e (Datenverarbeitung
        im öffentlichen Interesse) oder f (Datenverarbeitung auf Grundlage einer Interessenabwägung) DS-GVO erfolgt,
        Widerspruch einzulegen.
        <br>
        <br>
        Dies gilt auch für ein auf diese Bestimmungen gestütztes Profiling im Sinne von Art. 4 Nr. 4 DS-GVO.
        <br>
        <br>

        Legen Sie Widerspruch ein, werden wir Ihre personenbezogenen Daten nicht mehr verarbeiten, es sei denn, wir
        können zwingende berechtigte Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten
        überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.
        <br>
        <br>
        In Einzelfällen verarbeiten wir personenbezogene Daten, um Direktwerbung zu betreiben. Sie können jederzeit
        Widerspruch gegen die Verarbeitung der personenbezogenen Daten zum Zwecke derartiger Werbung einlegen. Dies gilt
        auch für das Profiling, soweit es mit solcher Direktwerbung in Verbindung steht. Widersprechen Sie gegenüber uns
        der Verarbeitung für Zwecke der Direktwerbung, so werden wir die personenbezogenen Daten nicht mehr für diese
        Zwecke verarbeiten.
        <br>
        <br>

        Zudem haben Sie das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, gegen die Sie
        betreffende Verarbeitung personenbezogener Daten, die bei uns zu wissenschaftlichen oder historischen
        Forschungszwecken oder zu statistischen Zwecken gemäß Art. 89 Abs. 1 DS-GVO erfolgen, Widerspruch einzulegen, es
        sei denn, eine solche Verarbeitung ist zur Erfüllung einer im öffentlichen Interesse liegenden Aufgabe
        erforderlich.
        <br>
        <br>
        Ihnen steht es frei, im Zusammenhang mit der Nutzung von Diensten der Informationsgesellschaft, ungeachtet der
        Richtlinie 2002/58/EG, Ihr Widerspruchsrecht mittels automatisierter Verfahren auszuüben, bei denen technische
        Spezifikationen verwendet werden.
        <br>
        <br>
        <h2>19.8 Widerruf einer datenschutzrechtlichen Einwilligung</h2>
        Sie haben das Recht, eine Einwilligung zur Verarbeitung personenbezogener Daten jederzeit mit Wirkung für die
        Zukunft zu widerrufen.
        <br>
        <br>

        <h2>19.9 Beschwerde bei einer Aufsichtsbehörde</h2>
        Sie haben das Recht, sich bei einer für Datenschutz zuständigen Aufsichtsbehörde über unsere Verarbeitung
        personenbezogener Daten zu beschweren.
        <br>
        <br>

        <h1>20. Routinemäßige Speicherung, Löschung und Sperrung von personenbezogenen Daten</h1>
        Wir verarbeiten und speichern Ihre personenbezogenen Daten nur für den Zeitraum, der zur Erreichung des
        Speicherungszwecks erforderlich ist oder sofern dies durch die Rechtsvorschriften, welchen unser Unternehmen
        unterliegt, vorgesehen wurde.
        <br>
        <br>

        Entfällt der Speicherungszweck oder läuft eine vorgeschriebene Speicherfrist ab, werden die personenbezogenen
        Daten routinemäßig und entsprechend den gesetzlichen Vorschriften gesperrt oder gelöscht.
        <br>
        <br>

        <h1>21. Dauer der Speicherung von personenbezogenen Daten</h1>
        Das Kriterium für die Dauer der Speicherung von personenbezogenen Daten ist die jeweilige gesetzliche
        Aufbewahrungsfrist. Nach Ablauf der Frist werden die entsprechenden Daten routinemäßig gelöscht, sofern sie
        nicht mehr zur Vertragserfüllung oder Vertragsanbahnung erforderlich sind
    </div>

    <div class="flex column content-width card-shadow">
        <h1>Einwilligung in die Datenverarbeitung und -nutzung</h1>
        Mit der Bestätigung der Checkbox bei der Anmeldung/Registrierung oder Buchung des von Ihnen ausgewählten
        Angebots willigen Sie ein, dass die von Ihnen in der Anmeldemaske erhobenen Daten sowie die IP-Adresse wie folgt
        verwendet werden können:

        Wir sind berechtigt Ihnen per Post, SMS, Mail, WhatsApp oder Telefon Informationen zu Angeboten zukommen zu
        lassen, und sie herzu einzuladen. Bei diesen Angeboten handelt es sich um Mehrwertangebote für Studenten unserer
        Plattform "Hochschulinitiative-Deutschland"(www.hochschulinitiative-deutschland.de) oder "Talentagent"
        (www.talentagent.de) Sie können dieser Einwilligung jederzeit durch eine formlose Mail an info&#64;talentagent.de
        widersprechen.
    </div>

    <div class="flex column content-width card-shadow">
        <h1>Einwilligung in die Datenverarbeitung und -nutzung bei Anmeldung zu einem B2B-Webinar</h1>
        Mit der Bestätigung der Checkbox bei der Anmeldung/Registrierung oder Buchung des von Ihnen ausgewählten
        Angebots willigen Sie ein, dass die von Ihnen in der Anmeldemaske erhobenen Daten sowie die IP-Adresse wir folgt
        verwendet werden können: Wir sind berechtigt Ihnen per Post, SMS, E-Mail, WhatsApp oder Telefon Informationen
        und Insights zur Absolventenzielgruppe sowie Informationen zu weiteren relevanten Angeboten im Bereich
        Einstiegsjobvermittlung zukommen zu lassen und Sie hierzu ggf. einzuladen. Sie können dieser Einwilligung
        jederzeit durch eine formlose E-Mail an info&#64;talentagent.de widersprechen.
    </div>
</div>
