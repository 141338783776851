<div class="webinar-highlight card-shadow height-100" *ngIf="size == 'Medium'">
  <div class="flex">
    <img [src]="webinarImage" alt="">
    <div class="upcoming-tag">Nächstes Webinar</div>
  </div>
  <div class="flex row start-center margin-vertical" *ngIf="webinarData?.dateTime">
    <p class="webinar-info">{{webinarData.dateTime | date:'dd.MM.yy' }}</p>
    <p class="webinar-info">|</p>
    <p class="webinar-info">{{webinarData.dateTime | date : 'hh:mm'}}</p>
  </div>
  <h3 class="webinar-title">{{webinarData.title}}</h3>
  <h4 class="webinar-description">{{webinarData.shortDescription}}</h4>
  <a class="more-info" routerLink="/webinar/{{webinarData.id}}">Mehr erfahren</a>
</div>


<div *ngIf="size == 'Small'"
     class="webinar-card card-shadow flex row gap-lg start height-100">
  <div class="flex">
    <img [src]="webinarImage" alt="">
  </div>

  <div class="flex column flex-space-between height-100">
    <div class="flex column">
      <div class="flex row start-center margin-vertical" *ngIf="webinarData.dateTime">
        <p class="webinar-info">{{webinarData.dateTime | date :'dd.MM.yy' }}</p>
        <p class="webinar-info">|</p>
        <p class="webinar-info">{{webinarData.dateTime | date : 'h:mm'}}</p>
      </div>

      <h3 class="webinar-title">{{webinarData.title}}</h3>
    </div>

    <a class="more-info" routerLink="/webinar/{{webinarData.id}}">Mehr erfahren</a>
  </div>
</div>

<div *ngIf="size == 'Large'"
     class="large-webinar-card card-shadow flex gap-lg start height-100" [ngClass]="{'row': !mediaService.isMobile(), 'column': mediaService.isMobile()}">
  <div class="flex " [ngClass]="{'width-33': !mediaService.isMobile(), 'width-100': mediaService.isMobile()}">
    <img [src]="webinarImage" alt="">
    <div class="upcoming-tag">Nächstes Webinar</div>
  </div>

  <div class="flex column flex-space-between height-100">
    <div class="flex column">
      <div class="flex row start-center margin-vertical" *ngIf="webinarData.dateTime">
        <p class="webinar-info">{{webinarData.dateTime | date :'dd.MM.yy' }}</p>
        <p class="webinar-info">|</p>
        <p class="webinar-info">{{webinarData.dateTime | date : 'h:mm'}}</p>
      </div>

      <h3 class="webinar-title">{{webinarData.title}}</h3>
      <h4 class="webinar-description">{{webinarData.shortDescription}}</h4>
    </div>

    <a class="more-info" routerLink="/webinar/{{webinarData.id}}">Mehr erfahren</a>
  </div>
</div>
