import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {ConsultantEmploymentPositionData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.consultant.ConsultantEmploymentPositionController
 */

@Injectable()
@ResourceParams({})
export class ConsultantEmploymentPositionResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/consultant/positions/{employmentPositionId}',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getSingleEmploymentPositionById: IResourceMethodObservableStrict<null, null, {employmentPositionId: number}, ConsultantEmploymentPositionData> | undefined
    getSingleEmploymentPositionById(employmentPositionId: number): Promise<ConsultantEmploymentPositionData> {
        if (!this._getSingleEmploymentPositionById) throw new Error("resource has not been properly initialized")
        return this._getSingleEmploymentPositionById(null, null, {employmentPositionId: employmentPositionId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/consultant/talent/{talentId}/primePositions/{employmentPositionId}/markAsUnsuitable',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _markPrimePositionAsUnsuitable: IResourceMethodObservableStrict<string, null, {employmentPositionId: number, talentId: number}, null> | undefined
    markPrimePositionAsUnsuitable(requestBody: string, pathVariables: {employmentPositionId: number, talentId: number}): Promise<null> {
        if (!this._markPrimePositionAsUnsuitable) throw new Error("resource has not been properly initialized")
        return this._markPrimePositionAsUnsuitable(requestBody, null, pathVariables).toPromise()
    }

}