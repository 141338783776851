<div
        [ngClass]="{'padding-vertical-lg': mediaService.getScreenWidth() > 960}"
        class="width-100 flex center column">

  <section *ngIf="mediaService.getScreenWidth() > 960" class="flex column center padding-vertical-lg">
    <img src="assets/images/talentagent-logo.png" alt="">
  </section>

  <section class="width-100 height-100 flex column center ">
    <div
            [ngClass]="{'row': mediaService.getScreenWidth() > 960, 'column-reverse': mediaService.getScreenWidth() <= 960}"
            class="demo-desk-container flex center-start">
      <div
              [ngClass]="{'width-50': mediaService.getScreenWidth() > 960, 'width-100': mediaService.getScreenWidth() <= 960}"
              class=" padding flex start column">
        <img  *ngIf="mediaService.getScreenWidth() > 960" class="header-logo" src="assets/images/uniwunder_fav.png" alt="">
        <img  *ngIf="mediaService.getScreenWidth() <= 960" class="margin-bottom" src="assets/images/talentagent-logo.png" alt="">

        <!--        <p class="color-accent bold-text"></p>-->
        <p
                [ngClass]="{'bold-text': mediaService.getScreenWidth() > 960, 'large-text': mediaService.getScreenWidth() <= 960}"
                class=" color-marine">Lerne in 20 Minuten Deutschlands größte Absolventenplattform kennen:</p>
        <ul>
          <li class="regular-text">Erfahre, wie du über Talentagent Nachwuchskräfte finden kannst</li>
          <li class="regular-text">Erhalte detaillierte Einblicke in die Plattform</li>
          <li class="regular-text">Lerne, wie deine Stellenanzeige junge Talente begeistert</li>
        </ul>
        <div class="divider" style="opacity: 0.35;" ></div>
        <p class="regular-text">Wir freuen uns auf den gemeinsamen Austausch und zeigen dir genau, wie dich unsere Plattform bei deinem Talent-Recruiting unterstützt.</p>
      </div>

      <div *ngIf="mediaService.getScreenWidth() > 960" class="absolute-divider"></div>

      <div *ngIf="mediaService.getScreenWidth() <= 960" class="divider"></div>

      <div [ngClass]="{'width-50': mediaService.getScreenWidth() > 960, 'width-100': mediaService.getScreenWidth() <= 960}">
        <div id="demodesk-iframe-inserter"></div>
      </div>
    </div>
  </section>

  <section class="padding-xl"></section>
</div>
