import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {environment} from "../../../../environments/environment";
import {Router} from "@angular/router";
import {AnalyticsService} from "../../services/gdprconsent/analytics.service";
import {UtmService} from '../../services/utm.service';
import {DemodeskService} from "../../../general/demo-booking/demodesk.service";

@Component({
  selector: 'burger-menu',
  templateUrl: './burger-menu.component.html',
  styleUrl: './burger-menu.component.scss'
})
export class BurgerMenuComponent implements OnInit{

  @Input() init: boolean;
  @Output() opened = new EventEmitter<any>();

  @ViewChild("menu") menu: ElementRef

  active = false;
  show = false

  constructor(
    public router: Router,
    private analyticsService: AnalyticsService,
    private utmService: UtmService,
    private demodeskService: DemodeskService
  ) {
  }

  ngOnInit() {
    this.active = this.init || false;
  }

  onBurgerClicked() {
    if (this.active == true) this.close()
    else this.open()
  }

  open() {
    this.active = true
    this.show = true
    this.opened.emit()
  }

  close() {
    if (!this.menu) return
    this.active = false
    this.menu.nativeElement.classList.add("hide")
    setTimeout(() => {
      this.show = false
    }, 200)
  }

  routeToTalentAgent() {
    window.open(environment.talentAgentFrontendUrl, "_blank")
  }

  routeToTalentAgentOb() {
    window.open(environment.talentAgentFrontendUrl +  '/onboarding' + this.utmService.getUtmParamsAsString())
  }
  routeToCompanyLogin(){
    window.open(environment.companyFrontendUrl + this.utmService.getUtmParamsAsString())
  }

  routeToDemoDesk() {
      this.demodeskService.navToContactForm('MobileMenu')
  }

}
