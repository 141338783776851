import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {JsonWrappedValue, TalentEventInterestingCompanyData, EventRegistrationData, PublicEventData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.talent.TalentEventController
 */

@Injectable()
@ResourceParams({})
export class TalentEventResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/talent/events/all',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getAllEventsForTalent: IResourceMethodObservableStrict<null, null, null, PublicEventData[]> | undefined
    getAllEventsForTalent(): Promise<PublicEventData[]> {
        if (!this._getAllEventsForTalent) throw new Error("resource has not been properly initialized")
        return this._getAllEventsForTalent(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/talent/events/matching',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getAllMatchingEventsForTalent: IResourceMethodObservableStrict<null, null, null, PublicEventData[]> | undefined
    getAllMatchingEventsForTalent(): Promise<PublicEventData[]> {
        if (!this._getAllMatchingEventsForTalent) throw new Error("resource has not been properly initialized")
        return this._getAllMatchingEventsForTalent(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/talent/events/{id}/companies/interestingState',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getCompaniesForTalentWithInterestingState: IResourceMethodObservableStrict<null, null, {id: number}, TalentEventInterestingCompanyData[]> | undefined
    getCompaniesForTalentWithInterestingState(id: number): Promise<TalentEventInterestingCompanyData[]> {
        if (!this._getCompaniesForTalentWithInterestingState) throw new Error("resource has not been properly initialized")
        return this._getCompaniesForTalentWithInterestingState(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/talent/events/{id}/registrationState',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getEventRegistrationState: IResourceMethodObservableStrict<null, null, {id: number}, JsonWrappedValue<('Unconfirmed' | 'Confirmed' | 'Canceled' | 'Annulled')>> | undefined
    getEventRegistrationState(id: number): Promise<('Unconfirmed' | 'Confirmed' | 'Canceled' | 'Annulled')> {
        if (!this._getEventRegistrationState) throw new Error("resource has not been properly initialized")
        return this._getEventRegistrationState(null, null, {id: id}).toPromise().then((result) => result.value)
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/talent/events/{id}/registerTalent',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _registerTalentForEvent: IResourceMethodObservableStrict<EventRegistrationData, null, {id: number}, JsonWrappedValue<('Unconfirmed' | 'Confirmed' | 'Canceled' | 'Annulled')>> | undefined
    registerTalentForEvent(requestBody: EventRegistrationData, id: number): Promise<('Unconfirmed' | 'Confirmed' | 'Canceled' | 'Annulled')> {
        if (!this._registerTalentForEvent) throw new Error("resource has not been properly initialized")
        return this._registerTalentForEvent(requestBody, null, {id: id}).toPromise().then((result) => result.value)
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/talent/eventRegistrations/event/{eventId}/companies/{companyId}/interesting',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _updateInterestingCompanyForTalent: IResourceMethodObservableStrict<null, {interesting: boolean}, {eventId: number, companyId: number}, JsonWrappedValue<boolean>> | undefined
    updateInterestingCompanyForTalent(pathVariables: {eventId: number, companyId: number}, requestParams: {interesting: boolean}): Promise<boolean> {
        if (!this._updateInterestingCompanyForTalent) throw new Error("resource has not been properly initialized")
        return this._updateInterestingCompanyForTalent(null, requestParams, pathVariables).toPromise().then((result) => result.value)
    }

}