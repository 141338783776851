import {Component, ElementRef, ViewChild} from '@angular/core';
import {MediaService} from "../../utility/services/media.service";
import {AnimationsService} from "../../utility/services/animations.service";
import {WebinarData} from "../../../generated/data";
import {CaseStudyData} from "../case-studies/single-case-study/single-case-study.component";
import {PublicLandingPageResource, PublicWebinarResource} from "../../../generated/resources";
import {MetaService} from '../../utility/services/meta.service';
import {AnalyticsService} from '../../utility/services/gdprconsent/analytics.service';
import {environment} from '../../../environments/environment';
import {MetaDefinition} from "@angular/platform-browser";
import {DemodeskService} from "../demo-booking/demodesk.service";

@Component({
    selector: 'app-company-page',
    templateUrl: './company-page.component.html',
    styleUrl: './company-page.component.scss'
})
export class CompanyPageComponent {

    environment = environment

    webinarData: WebinarData[] = []
    caseStudyData: CaseStudyData = {
        companyName: "Userlike",
        studyTitle: "Userlike generiert mit Talentagent gezielt dreimal mehr Bewerbungen von Top-Absolventen",
        companyLogo: 1,
        videoUrl: "https://www.youtube.com/watch?v=dQw4w9WgXcQ",
        videoTagline: "Userlike ist die führende deutsche Plattform für Kunden-Messaging und KI-gestützte Support-Automatisierung.",
        studyText:
            "Die Customer-Experience-Plattform Userlike setzt in ihrem Recruiting einen besonderen Fokus auf das Potenzial junger Talente. " +
            "Für ihr hauseigenes Traineeship-Programm suchte das Unternehmen gezielt nach Absolventen - und fand mit Talentagent die entscheidende Expertise dafür.\n" +
            "\n" + "<br>" + "<br>" +
            "Userlike bietet innovative Kommunikationslösungen für Unternehmen, die auch bei der digital versierten Generation Z Anklang finden." +
            " Auf Talentagent konnte Userlike genau diese Absolventen, deren Technikaffinität und Know-How einen idealen Match für das hauseigene Produkt darstellt, " +
            "finden und ansprechen. Das zeigt sich am Beispiel der ersten Trainee, die durch Talentagent zu Userlike kam:" +
            " Sie erzielte bereits kurze Zeit nach ihrem Hiring bemerkenswerte Erfolge in ihrer Position und bringt täglich ein hohes Maß an Freude für ihre Aufgaben mit.\n" +
            "\n" + "<br>" + "<br>" +
            "Die Partnerschaft hat es Userlike ermöglicht, ihren Recruiting-Funnel stets mit vielversprechenden Gesprächen" +
            " und Potentialen versorgt zu wissen. Hierbei sorgt Talentagent bereits im Vorhinein für zielgruppengerechte Stelleninserate und" +
            " qualitativ hochwertige Bewerber, sodass sich Userlike zu 100% auf die stattfindenden Kandidateninterviews fokussieren kann.",
        quoteText:
            "Userlike generiert mit Talentagent gezielt dreimal mehr Bewerbungen von Top-Absolvent:innen",
        quoteAuthor: "Kristina",
        quoteAuthorTitle: "Recruiterin bei Userlike"
    }

    primeCompanyTooltipText: string = "Mit dem Premiumprofil wird dein Unternehmen auf der Plattform prominent hervorgehoben und erhält maximale Sichtbarkeit – inklusive Integration von Bildern und Videos."
    topJobTooltipText: string = "Die Top Job Platzierung lässt deine Stellenanzeige aus der Masse herausstechen, um schneller mehr Bewerber anzuziehen – dank erstklassiger Positionierung im Job-Feed der Absolventen und ansprechender visueller Optimierungen."

    @ViewChild("contactSection") contactSection: ElementRef

    carouselReady: boolean = false

    constructor(
        public mediaService: MediaService,
        private animationsService: AnimationsService,
        private webinarResource: PublicWebinarResource,
        private metaService: MetaService,
        private analyticsService: AnalyticsService,
        private demodeskService: DemodeskService,
        private publicLandingPageResource: PublicLandingPageResource,
    ) {
    }

    ngOnInit() {



        setTimeout(() => {
          this.carouselReady = true
        }, 100)
        this.handleScrollAnimations()
        this.loadWebinars()
        this.addMetaTags()
    }




    addMetaTags() {
        let tag: MetaDefinition = {
            name: 'description',
            content: 'Sie suchen junge Talente für Ihr Unternehmen? Finden Sie im Talentagent passende Top-Bewerber in der größten Absolventenplattform Deutschlands!',
        }
        this.metaService.setMetaTag(tag)
    }

    navToContact(context: string) {
        this.demodeskService.navToContactForm(context)
    }

    reportCaseStudyClick() {
        this.analyticsService.reportCaseStudyClick('CompanyPage', 'Userlike')
    }

    loadWebinars() {
        this.webinarResource.getAllWebinars().then((result: WebinarData[]) => {
            this.webinarData = result
        })
    }


    handleScrollAnimations() {

        this.animationsService.handleOnScrollAnimation('.hidden', 'show', true)
        this.animationsService.handleOnScrollAnimation('.step-text', 'slideInBottom', true)
        this.animationsService.handleOnScrollAnimation('.usp', 'bounceIn', false)
        this.animationsService.handleOnScrollAnimation('.jobs', 'jobSlide', false)
        this.animationsService.handleOnScrollAnimation('.arrow', 'revealFromTop', false)
        this.animationsService.handleOnScrollAnimation('.highlight', 'highlight-show', false)
        this.animationsService.handleOnScrollAnimation('.partner-logo', 'slideInLeft', true)
    }
}
