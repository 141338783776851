import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {PublicBundleResource} from "../generated/hid/resources";
import {MediaService} from "./utility/services/media.service";
import {environment} from "../environments/environment";
import {AnalyticsService} from "./utility/services/gdprconsent/analytics.service";
import {LocalStorageService} from "./utility/services/local-storage.service";
import {NavbarService} from "./utility/services/navbar.service";
import {UtmService} from "./utility/services/utm.service";
import {DemodeskService} from "./general/demo-booking/demodesk.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit{
  title = 'frontend-public-new';
  showNavbar = true;

  constructor(
    private hidhome: PublicBundleResource,
    public mediaService: MediaService,
    public router: Router,
    private analyticsService: AnalyticsService,
    private localStorageService: LocalStorageService,
    private navbarService: NavbarService,
    private utmService: UtmService,
    private demodeskService: DemodeskService
  ) {
      this.utmService.startUtmParamsSubscription()
  }

  ngOnInit() {
    if (this.localStorageService.getGDPRConsent().consent_tiktok) this.localStorageService.addTikTokScriptToHead()
    this.localStorageService.updateSessionTracking()
    this.navbarService.navBarStatus.subscribe((status: boolean) => {
      this.showNavbar = status
    })
  }

  routeToSocialMedia(url: string) {
    window.open(url, "_blank")
  }

  routeToTalentAgent() {
    window.open(environment.talentAgentFrontendUrl + this.utmService.getUtmParamsAsString())
  }

  routeToTalentAgentOb() {
    window.open(environment.talentAgentFrontendUrl +  '/onboarding' + this.utmService.getUtmParamsAsString())
  }
  routeToCompanyLogin(){
    window.open(environment.companyFrontendUrl + this.utmService.getUtmParamsAsString())
  }

  routeToHID() {
    window.open("https://hochschulinitiative-deutschland.de/home")
  }

  routeToDemoDesk(context: string) {
      this.demodeskService.navToContactForm(context)
  }

  routeToUniwunder() {
    window.open("https://uniwunder.com/")
  }



}
