import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {JsonWrappedValue, ApplicationFilterData, ApplicationCheckData, AdminApplicationDetailsData, ApplicationLogEntryData, AdminApplicationStateCountsData, AdminApplicationData, AdminApplicationBlockedData, PagedData, RequestedApplicationDocumentsData, CompanyHiringInfoUpdateData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.admin.AdminApplicationController
 */

@Injectable()
@ResourceParams({})
export class AdminApplicationResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/admin/applications/{id}/accept',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _acceptTalentApplication: IResourceMethodObservableStrict<null, null, {id: number}, JsonWrappedValue<boolean>> | undefined
    acceptTalentApplication(id: number): Promise<boolean> {
        if (!this._acceptTalentApplication) throw new Error("resource has not been properly initialized")
        return this._acceptTalentApplication(null, null, {id: id}).toPromise().then((result) => result.value)
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/admin/applications/{applicationId}/change',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _changeApplicationState: IResourceMethodObservableStrict<null, {newState: ('Draft' | 'Sent' | 'Denied' | 'Withdrawn' | 'Granted' | 'Failed' | 'Interesting' | 'AppProcessEnter' | 'ContractSent' | 'Hired')}, {applicationId: number}, null> | undefined
    changeApplicationState(applicationId: number, requestParams: {newState: ('Draft' | 'Sent' | 'Denied' | 'Withdrawn' | 'Granted' | 'Failed' | 'Interesting' | 'AppProcessEnter' | 'ContractSent' | 'Hired')}): Promise<null> {
        if (!this._changeApplicationState) throw new Error("resource has not been properly initialized")
        return this._changeApplicationState(null, requestParams, {applicationId: applicationId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/admin/applications/positions/{id}/states',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _countApplicationStates: IResourceMethodObservableStrict<null, null, {id: number}, AdminApplicationStateCountsData> | undefined
    countApplicationStates(id: number): Promise<AdminApplicationStateCountsData> {
        if (!this._countApplicationStates) throw new Error("resource has not been properly initialized")
        return this._countApplicationStates(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/admin/talents/{talentId}/applications/createSubsequent',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _createSubsequentApplication: IResourceMethodObservableStrict<null, {positionId: number}, {talentId: number}, null> | undefined
    createSubsequentApplication(talentId: number, requestParams: {positionId: number}): Promise<null> {
        if (!this._createSubsequentApplication) throw new Error("resource has not been properly initialized")
        return this._createSubsequentApplication(null, requestParams, {talentId: talentId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/admin/applications/{id}/deny',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _denyTalentApplication: IResourceMethodObservableStrict<AdminApplicationBlockedData, null, {id: number}, JsonWrappedValue<boolean>> | undefined
    denyTalentApplication(requestBody: AdminApplicationBlockedData, id: number): Promise<boolean> {
        if (!this._denyTalentApplication) throw new Error("resource has not been properly initialized")
        return this._denyTalentApplication(requestBody, null, {id: id}).toPromise().then((result) => result.value)
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/admin/applications',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getAllApplications: IResourceMethodObservableStrict<ApplicationFilterData, {page?: number, pageSize?: number}, null, PagedData<AdminApplicationData>> | undefined
    getAllApplications(requestBody: ApplicationFilterData, requestParams?: {page?: number, pageSize?: number}): Promise<PagedData<AdminApplicationData>> {
        if (!this._getAllApplications) throw new Error("resource has not been properly initialized")
        return this._getAllApplications(requestBody, requestParams || {}, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/admin/applications/export',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getAllApplicationsExcel: IResourceMethodObservableStrict<ApplicationFilterData, null, null, Blob> | undefined
    getAllApplicationsExcel(requestBody: ApplicationFilterData): Promise<Blob> {
        if (!this._getAllApplicationsExcel) throw new Error("resource has not been properly initialized")
        return this._getAllApplicationsExcel(requestBody, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/admin/applications/{id}/details',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getApplicationDetails: IResourceMethodObservableStrict<null, null, {id: number}, AdminApplicationDetailsData> | undefined
    getApplicationDetails(id: number): Promise<AdminApplicationDetailsData> {
        if (!this._getApplicationDetails) throw new Error("resource has not been properly initialized")
        return this._getApplicationDetails(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/admin/applications/{id}',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getApplicationLogEntriesById: IResourceMethodObservableStrict<null, null, {id: number}, ApplicationLogEntryData[]> | undefined
    getApplicationLogEntriesById(id: number): Promise<ApplicationLogEntryData[]> {
        if (!this._getApplicationLogEntriesById) throw new Error("resource has not been properly initialized")
        return this._getApplicationLogEntriesById(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/admin/applications/positions/{id}',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getApplicationsForEmploymentPosition: IResourceMethodObservableStrict<null, null, {id: number}, AdminApplicationData[]> | undefined
    getApplicationsForEmploymentPosition(id: number): Promise<AdminApplicationData[]> {
        if (!this._getApplicationsForEmploymentPosition) throw new Error("resource has not been properly initialized")
        return this._getApplicationsForEmploymentPosition(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/admin/applications/talents/{id}',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getApplicationsForTalent: IResourceMethodObservableStrict<null, null, {id: number}, ApplicationCheckData[]> | undefined
    getApplicationsForTalent(id: number): Promise<ApplicationCheckData[]> {
        if (!this._getApplicationsForTalent) throw new Error("resource has not been properly initialized")
        return this._getApplicationsForTalent(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/admin/applications/{id}/documents',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getRequestedAdditionalDocumentsForTalentApplication: IResourceMethodObservableStrict<null, null, {id: number}, RequestedApplicationDocumentsData[]> | undefined
    getRequestedAdditionalDocumentsForTalentApplication(id: number): Promise<RequestedApplicationDocumentsData[]> {
        if (!this._getRequestedAdditionalDocumentsForTalentApplication) throw new Error("resource has not been properly initialized")
        return this._getRequestedAdditionalDocumentsForTalentApplication(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/admin/applications/{id}/documents',
        endpoint: 'com.uniwunder.talentagent.main',
        keepEmptyBody: true
    })
    private _requestAdditionalDocumentsForTalentApplication: IResourceMethodObservableStrict<string[], null, {id: number}, null> | undefined
    requestAdditionalDocumentsForTalentApplication(requestBody: string[], id: number): Promise<null> {
        if (!this._requestAdditionalDocumentsForTalentApplication) throw new Error("resource has not been properly initialized")
        return this._requestAdditionalDocumentsForTalentApplication(requestBody, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/admin/applications/{id}/adminNote/edit',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _updateApplicationHiringInformation: IResourceMethodObservableStrict<CompanyHiringInfoUpdateData, {adminNote: string}, {id: number}, null> | undefined
    updateApplicationHiringInformation(requestBody: CompanyHiringInfoUpdateData, id: number, requestParams: {adminNote: string}): Promise<null> {
        if (!this._updateApplicationHiringInformation) throw new Error("resource has not been properly initialized")
        return this._updateApplicationHiringInformation(requestBody, requestParams, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/admin/applications/{id}/updateHiringInformation',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _updateHiringInformationForApplication: IResourceMethodObservableStrict<CompanyHiringInfoUpdateData, null, {id: number}, CompanyHiringInfoUpdateData> | undefined
    updateHiringInformationForApplication(requestBody: CompanyHiringInfoUpdateData, id: number): Promise<CompanyHiringInfoUpdateData> {
        if (!this._updateHiringInformationForApplication) throw new Error("resource has not been properly initialized")
        return this._updateHiringInformationForApplication(requestBody, null, {id: id}).toPromise()
    }

}