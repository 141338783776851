import {Component, Input, OnInit} from '@angular/core';
import {environment} from "../../../../environments/environment";
import {MediaService} from "../../../utility/services/media.service";
import {MatDialog} from "@angular/material/dialog";
import {ShareService} from "../../../utility/services/share.service";
import {AnalyticsService} from "../../../utility/services/gdprconsent/analytics.service";

@Component({
  selector: 'app-career-guide-onboarding-catcher',
  templateUrl: './career-guide-onboarding-catcher.component.html',
  styleUrl: './career-guide-onboarding-catcher.component.scss'
})
export class CareerGuideOnboardingCatcherComponent implements OnInit {

  @Input() onSide: boolean = true
  @Input() content: string = ""
  @Input() buttonText: string = ""

  constructor(
    public mediaService: MediaService,
    private shareService: ShareService,
    private analyticsService: AnalyticsService
  ) {
  }

  ngOnInit() {
  }

  routeToTalentAgent() {
    this.analyticsService.reportCareerGuideCtaClick(true)
    window.open(environment.talentAgentFrontendUrl + '/onboarding?utm_source=talentagent&utm_medium=karriereguide&utm_campaign=proposal-box', "_blank")
  }

  openShareDialog() {
    this.shareService.openSharingDialog('Hey, schau dir mal diesen Artikel an: ', window.location.href)
  }

}
